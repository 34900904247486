<template>
  <div class="toast-wrapper" :data-cy="isError ? 'error-toast' : 'toast'">
    <div class="toast" :class="{ 'toast--error': isError }">
      <div class="toast__info" @click="click">
        <div
          class="toast-info__title tp-text-subtitle-semibold-black"
          v-html="title"
        ></div>
        <div
          class="toast-info__description tp-text-body mt-1"
          v-if="description"
          v-html="description"
        ></div>
      </div>
      <div class="toast__close" @click="close">
        <img :src="require(`@/assets/images/icons/x-icon.svg`)" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    description: String,
    action: Function,
    isError: Boolean,
    doNotClose: Boolean,
  },
  mounted() {
    if (!this.doNotClose) {
      setTimeout(() => {
        this.close();
      }, 7000);
    }
  },
  methods: {
    close() {
      this.$store.dispatch('closeToast');
    },
    click() {
      if (typeof this.action !== 'undefined') {
        this.action();
      }
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.toast-wrapper {
  position: fixed;
  top: 50px;
  width: 100%;
  z-index: 500;
}
.toast {
  background: $color-white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3), 0px 12px 24px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  border-left: 8px solid var(--color-text-main);
  margin: 0px 30px;
  position: relative;

  &__description {
    line-height: 16px;
  }

  &--error {
    border-left: 8px solid $color-red !important;
  }

  &__info {
    padding: 16px;
    width: 90%;
  }

  &__close {
    position: absolute;
    top: 15px;
    right: 15px;
    line-height: 0;
  }
}
</style>
