import Vue from 'vue';
import VueRouter from 'vue-router';
import ScanQr from '../views/ScanQr.vue';
import Menu from '../views/Menu.vue';
import VueMeta from 'vue-meta';
import Preview from '../views/Preview.vue';
import Order from '../views/Order.vue';
import Payment from '../views/Payment.vue';
import PayInPerson from '../views/PayInPerson.vue';
import PayInPersonSuccess from '../views/PayInPersonSuccess.vue';
import PaymentSuccess from '../views/PaymentSuccess.vue';
import PaymentStatus from '../views/PaymentStatus.vue';
import PlaceOrder from '../views/PlaceOrder.vue';
import Orders from '../views/Orders.vue';
import Search from '../views/Search.vue';
import ExtraDetails from '../views/ExtraDetails.vue';
import SelectItems from '../views/SelectItems.vue';
import TipWaiter from '../views/TipWaiter.vue';
import TipSuccess from '../views/TipSuccess.vue';
import SelectScreen from '../views/SelectScreen.vue';
import ViewOrder from '../views/ViewOrder.vue';
import ViewKioskOrder from '../views/ViewKioskOrder.vue';
import MenuFilters from '../views/MenuFilters.vue';
import PaymentHistory from '../views/PaymentHistory.vue';
import Donate from '../views/Donations/Donate.vue';
import DonateSuccess from '../views/Donations/DonateSuccess.vue';

import LoyaltyPreview from '../views/Loyalty/ProgramPreview.vue';
import EnrollLoyalty from '../views/Loyalty/EnrollLoyalty.vue';

import NewUserForm from '../views/Profile/NewUserForm.vue';
import Profile from '../views/Profile/Profile.vue';
import Register from '../views/Profile/Register.vue';
import ProfileDetails from '../views/Profile/ProfileDetails.vue';
import SignUp from '../views/Profile/SignUp.vue';
import SignIn from '../views/Profile/SignIn.vue';
import LoyaltyProgramDetails from '../views/Loyalty/LoyaltyProgramDetails.vue';
import SplashScreenPreview from '../views/SplashScreenPreview.vue';

// Food Court
import FoodCourt from '../views/FoodCourt/FoodCourtRestaurantList.vue';
import FoodCourtOrders from '../views/FoodCourt/FoodCourtOrders.vue';
import ViewFoodCourtOrder from '../views/FoodCourt/FoodCourtViewOrder.vue';

// WORLDLINE
import TerminalCommands from '../views/TerminalCommands.vue';

import RequestInvoice from '../views/RequestInvoice.vue';

Vue.use(VueRouter);
Vue.use(VueMeta);

const routes = [
  {
    path: '/:hash',
    name: 'ScanQr',
    component: ScanQr,
  },

  {
    path: '/preview/:hash',
    name: 'Preview',
    component: Preview,
  },
  {
    path: '/preview/:hash',
    name: 'SplashScreenPreview',
    component: SplashScreenPreview,
  },
  {
    path: '/menu/:session',
    name: 'Menu',
    component: Menu,
  },
  {
    path: '/select/:session',
    name: 'Select',
    component: SelectScreen,
  },
  {
    path: '/place-order/:session',
    name: 'PlaceOrder',
    component: PlaceOrder,
  },
  {
    path: '/order/:session',
    name: 'Order',
    component: Order,
  },
  {
    path: '/pay/:session/:order',
    name: 'Pay',
    component: Payment,
  },
  {
    path: '/pay-in-person/:session/:order',
    name: 'PayInPerson',
    component: PayInPerson,
  },
  {
    path: '/pay-in-person-success/:session/:paymentUUID',
    name: 'PayInPersonSuccess',
    component: PayInPersonSuccess,
  },
  {
    path: '/terminal-commands/:session',
    name: 'PayInPersonSuccess',
    component: TerminalCommands,
  },
  {
    path: '/view-order-headless/:session/:uniqueOrderNumber',
    name: 'ViewOrder',
    component: ViewOrder,
  },
  {
    path: '/view-kiosk-order/:session/:uniqueOrderNumber',
    name: 'ViewKioskOrder',
    component: ViewKioskOrder,
  },
  {
    path: '/success/:session',
    name: 'PaymentSuccess',
    component: PaymentSuccess,
  },
  {
    path: '/payment-status/:session',
    name: 'PaymentStatus',
    component: PaymentStatus,
  },
  {
    path: '/orders/:session',
    name: 'Orders',
    component: Orders,
  },
  {
    path: '/search/:session',
    name: 'Search',
    component: Search,
  },
  {
    path: '/extra-details/:session',
    name: 'ExtraDetails',
    component: ExtraDetails,
  },
  {
    path: '/select-items/:session',
    name: 'SelectItems',
    component: SelectItems,
  },
  {
    path: '/tip/:tipsQrCardHash',
    name: 'TipWaiter',
    component: TipWaiter,
  },
  {
    path: '/tip/success/:tipsQrCardHash',
    name: 'TipSuccess',
    component: TipSuccess,
  },
  {
    path: '/donation/:donateQrCardHash',
    name: 'Donate',
    component: Donate,
  },
  {
    path: '/donation/success/:donateQrCardHash',
    name: 'DonateSuccess',
    component: DonateSuccess,
  },
  {
    path: '/profile/:session',
    name: 'Profile',
    component: Profile,
  },
  {
    path: '/register/:session',
    name: 'Register',
    component: Register,
  },
  {
    path: '/profile/details/:session',
    name: 'ProfileDetails',
    component: ProfileDetails,
  },
  {
    path: '/profile/new-user-form/:session',
    name: 'NewUserForm',
    component: NewUserForm,
  },
  {
    path: '/profile/sign-up/:session',
    name: 'SignUp',
    component: SignUp,
  },
  {
    path: '/profile/sign-in/:session',
    name: 'SignIn',
    component: SignIn,
  },
  {
    path: '/menu-filters/:session',
    name: 'MenuFilters',
    component: MenuFilters,
  },
  {
    path: '/loyalty/preview/:session',
    name: 'LoyaltyPreview',
    component: LoyaltyPreview,
  },
  {
    path: '/loyalty/enroll/:session/:loyaltyProgramId',
    name: 'EnrollLoyalty',
    component: EnrollLoyalty,
  },
  {
    path: '/profile/loyalty-program-details/:session',
    name: 'LoyaltyProgramDetails',
    component: LoyaltyProgramDetails,
  },
  {
    path: '/payments/history/:session',
    name: 'PaymentHistory',
    component: PaymentHistory,
  },
  // FOOD COURT START
  {
    path: '/food-court/:foodCourtHash',
    name: 'FoodCourt',
    component: FoodCourt,
  },
  {
    path: '/food-court/orders/:foodCourtHash',
    name: 'FoodCourtOrders',
    component: FoodCourtOrders,
  },
  {
    path: '/food-court/view/order/:foodCourtHash/:session/:uniqueOrderNumber',
    name: 'ViewFoodCourtOrder',
    component: ViewFoodCourtOrder,
  },
  // FOOD COURT END
  {
    path: '/request-invoice/:paymentUuid',
    name: 'RequestInvoice',
    component: RequestInvoice,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
