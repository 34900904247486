<template>
  <div>
    <slot name="activator" :toggle="toggle"></slot>
    <v-bottom-sheet
      :value="isOpen"
      :max-width="maxAppWidth"
      @click:outside="close"
    >
      <v-sheet class="pa-4 pb-6" rounded="xl b-0" min-height="250px">
        <div class="close-wrapper">
          <img
            class="close"
            :src="require(`@/assets/images/icons/chevron-down-white-icon.svg`)"
            @click="close"
          />
        </div>
        <GreetLogo color="black" height="24" />
        <div class="tp-text-caption-gray mt-2">
          {{ $t('screens.selectScreen.greetDrawerText') }}
        </div>
        <SocialLinks :social-links="socialLinks" class="mt-9" />
        <div class="px-4 mt-9">
          <Button dark @click="navigateToGreetWebsite">
            {{ $t('screens.selectScreen.greetDrawerCta') }}
          </Button>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import Button from '@/components/Button.vue';
import GreetLogo from '@/components/GreetLogo.vue';
import SocialLinks from '@/components/common/SocialLinks.vue';

export default {
  components: {
    GreetLogo,
    Button,
    SocialLinks,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    socialLinks() {
      // global social links for the app found in main.js
      return [
        {
          name: 'facebook',
          link: this.greetFacebook,
        },
        {
          name: 'instagram',
          link: this.greetInstagram,
        },
      ];
    },
  },
  methods: {
    close() {
      this.isOpen = false;
    },
    toggle() {
      this.isOpen = !this.isOpen;
    },
    navigateToGreetWebsite() {
      // global greet website link found in main.js
      return window.open(this.greetWebsite, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.close-wrapper {
  position: relative;
  background: lightgray;
}

.close {
  border-radius: 32px;
  position: absolute;
  right: 0;
}
</style>
