<template>
  <v-container class="pa-0 container">
    <NavHeader
      :title="$t('screens.paymentSuccess.headerTitle')"
      :subtitle="tableName"
      @onBackClick="goBack"
    />
    <div v-if="isLoading" class="py-6 d-flex justify-center align-center">
      <v-progress-circular indeterminate color="primary" />
    </div>
    <div v-else>
      <div v-if="failedPayment">
        <LayoutCard rounded>
          <div class="text-center">
            <div class="d-flex justify-center">
              <lottie-vue-player
                src="/assets/order-status-animations/failed-payment.json"
                style="width: 100px; height:100px"
                autoplay
              />
            </div>
            <h4 class="pt-4" data-cy="failed-payment-title">
              {{ $t('screens.paymentSuccess.failed.title') }}
            </h4>
            <p
              class="mb-0 mt-4 tp-text-body"
              data-cy="failed-payment-description"
            >
              {{ $t('screens.paymentSuccess.failed.description') }}
            </p>
          </div>
        </LayoutCard>
        <div class="pt-6 pb-10 mx-10">
          <Button dark @click="navigateToOrder">
            {{ $t('screens.paymentSuccess.goBackCtaLabel') }}
          </Button>
        </div>
      </div>
      <div v-else>
        <LayoutCard rounded>
          <div class="success-card" data-cy="payment-success-screen">
            <lottie-vue-player
              src="/assets/order-status-animations/successful-payment.json"
              style="width: 66px; height:66px"
              autoplay
            />
            <v-card
              color="rgba(72, 159, 49, 0.2)"
              class="pa-3 align-center text-center w-100 mt-3"
              flat
            >
              <span class="tp-text-body green-text">
                {{ $t('screens.paymentSuccess.successMessage') }}
              </span>
            </v-card>
            <div
              v-if="paidAmount"
              class="d-flex tp-text-subtitle-semibold justify-space-between align-center w-100 pt-5"
            >
              <span>{{ $t('screens.paymentSuccess.amountPaid') }}</span>
              <span data-cy="paid-amount">{{ amountFormatted }}</span>
            </div>
            <div
              class="d-flex tp-text-subtitle-semibold justify-space-between align-center w-100 pt-3"
            >
              <span>{{ $t('screens.paymentSuccess.leftToPay') }}</span>
              <span data-cy="left-to-pay">{{ leftToPayFormatted }}</span>
            </div>
            <div
              class="d-flex flex-row justify-space-between align-center w-100 pt-3"
            >
              <span>{{ $t('screens.order.paymentType') }}</span>
              <div class=" d-flex flex-row justify-space-between align-center">
                <div
                  v-if="getPaymentLastCardDigits"
                  class="d-flex align-center pr-3"
                >
                  <img
                    :src="require(`@/assets/images/icons/hidden-card-dots.svg`)"
                  />
                  <span>{{ getPaymentLastCardDigits }}</span>
                </div>
                <img
                  :src="
                    require(`@/assets/images/payments/${getPaymentIconName}.svg`)
                  "
                />
              </div>
            </div>
          </div>
          <div v-if="paymentId">
            <RequestReceiptButton
              v-if="paymentUuid"
              :session="session"
              :payment-uuid="paymentUuid"
            />
            <div v-if="requestInvoiceEnabled">
              <DividerWithText :text="$t('generic.or')" class="mt-3 mb-3" />
              <Button
                x-small
                no-shadow
                gray
                class="mt-0"
                @click="goToRequestInvoice"
              >
                {{ $t('components.requestInvoice.button') }}
              </Button>
            </div>
          </div>
        </LayoutCard>
        <LayoutCard v-if="uniqueOrderNumber" rounded>
          <LeaveReview :unique-order-number="uniqueOrderNumber" />
        </LayoutCard>
        <LayoutCard rounded :title="$t('screens.order.orderInformationTitle')">
          <div class="tp-text-body">
            <div class="mt-1">
              {{ $t('screens.order.orderTimeText', { time: orderDate }) }}
            </div>
            <div class="mt-1">{{ $t('screens.order.orderProviderText') }}</div>
          </div>
        </LayoutCard>
        <LayoutCard
          rounded
          :title="$t('screens.order.restaurantInformationTitle')"
        >
          <div class="tp-text-body">
            <div>{{ restaurantName }}</div>
            <div v-if="restaurantAddress" class="mt-1">
              {{ restaurantAddress }}
            </div>
            <div v-if="restaurantPhone" class="mt-1">
              {{ restaurantPhone }}
            </div>
          </div>
        </LayoutCard>
        <div class="pt-6 pb-10 mx-10">
          <Button dark @click="navigateToOrder">
            {{ $t('screens.paymentSuccess.goBackCtaLabel') }}
          </Button>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import LayoutCard from '@/components/LayoutCard.vue';
import NavHeader from '@/components/Navigation/NavHeader.vue';
import LeaveReview from '@/components/Reviews/Review.vue';
import Button from '@/components/Button.vue';
import RequestReceiptButton from '@/components/Payments/RequestReceiptButton.vue';
import DividerWithText from '@/components/common/DividerWithText.vue';

export default {
  components: {
    LayoutCard,
    NavHeader,
    LeaveReview,
    Button,
    RequestReceiptButton,
    DividerWithText,
  },
  data() {
    return {
      isLoading: false,
      uniqueOrderNumber: null,
      paidAmount: null,
      paidAt: null,
      paymentData: {},
      paymentId: null,
      failedPayment: false,
      paymentUuid: null,
    };
  },
  computed: {
    session() {
      return this.$store.state.session;
    },
    amountFormatted() {
      return this.$store.getters.getFormattedPrice(this.paidAmount);
    },
    leftToPayFormatted() {
      return this.$store.getters.getFormattedPrice(this.leftToPayAmount);
    },
    order() {
      return this.$store.getters.getPOSOrderByUniqueOrderNumber(
        this.uniqueOrderNumber
      );
    },
    orderItems() {
      if (!this.order) {
        return [];
      }
      return (
        this.order.items.map(item => {
          return {
            name: item.name,
            quantity: item.quantity,
            modifiers: item.modifiers,
            price: item.price,
            isPaid: item.is_paid,
          };
        }) || []
      );
    },
    tableName() {
      return this.$store.state.table.name;
    },
    restaurantName() {
      return this.$store.state.restaurant.name;
    },
    restaurantAddress() {
      return this.$store.state.restaurant.address;
    },
    restaurantPhone() {
      return this.$store.state.restaurant.phone;
    },
    orderDate() {
      if (!this.paidAt) {
        return '';
      }

      return new Date(this.paidAt).toLocaleString();
    },
    orderId() {
      return this?.order?.order_id;
    },
    getPaymentIconName() {
      if (this.paymentData.wallet) {
        if (this.paymentData.wallet === 'apple_pay') {
          return 'apple-pay';
        } else if (this.paymentData.wallet === 'google_pay') {
          return 'google-pay';
        }
      } else {
        if (this.paymentData.card_brand === 'visa') {
          return 'visa';
        } else if (this.paymentData.card_brand === 'mastercard') {
          return 'mastercard';
        } else if (this.paymentData.card_brand === 'american_express') {
          return 'american-express';
        } else if (this.paymentData.bank) {
          return 'bank';
        }
      }

      return 'default';
    },
    getPaymentLastCardDigits() {
      if (this.paymentData?.card_last4) {
        return this.paymentData.card_last4;
      }

      return false;
    },
    requestInvoiceEnabled() {
      return this.$store.getters.functionalityRequestInvoiceEnabled;
    },
    leftToPayAmount() {
      if (!this.order) {
        return null;
      }

      const alreadyPaid = this.order?.payments_sum;
      const orderSubtotal = this.order?.subtotal;

      if (alreadyPaid >= orderSubtotal) {
        return 0;
      }

      return orderSubtotal - alreadyPaid;
    },
  },
  async mounted() {
    const sessionToken = this.$route.params.session;
    this.isLoading = true;

    await this.$store.dispatch('fetchSessionData', { session: sessionToken });
    await this.$store.dispatch('fetchOrdersFromPOS');

    const uniqueOrderNumber = this.$route.query.orderNumber;
    const paymentUuid = this.$route.query.paymentUuid;

    this.uniqueOrderNumber = uniqueOrderNumber;
    this.paymentUuid = paymentUuid;

    await this.fetchPaymentDetails();

    this.isLoading = false;
  },
  methods: {
    navigateToOrder() {
      this.$router.push({
        name: 'Order',
        params: { session: this.session },
        query: { orderNumber: this.uniqueOrderNumber },
      });
    },
    navigateToMenu() {
      this.$router.push({
        name: 'Menu',
        params: { session: this.session },
      });
    },
    async fetchPaymentDetails() {
      const isNeopay = this.$route.query['neopay-callback'] === '1';

      if (isNeopay) {
        await this.fetchNeoPayPaymentData();
        return;
      }

      const data = await this.$store.dispatch('tryToGetLastPaymentIntent', {
        uniqueOrderNumber: this.uniqueOrderNumber,
        paymentUuid: this.paymentUuid,
      });

      this.paymentData = data.payment_data;
      this.paidAmount = data.amount + data.tips + data.transaction_fee;
      this.paidAt = data.paid_at;
      this.paymentId = data.payment_id;
      this.isLoading = false;
      this.paymentUuid = data.uuid;
    },
    async fetchNeoPayPaymentData() {
      const token = this.$route.query.token;
      const data = this.$route.query.data;
      const canceled = this.$route.query.canceled === '1';
      const pending = this.$route.query.pending === '1';

      const onPaymentSuccess = data => {
        this.isLoading = false;
        this.paymentData = data.payment_data;
        this.paidAmount = data.amount + data.tips + data.transaction_fee;
        this.paidAt = data.paid_at;
        this.paymentId = data.payment_id;
        this.paymentUuid = data.uuid;
      };
      const onPaymentError = () => {
        this.isLoading = false;
        this.failedPayment = true;
      };

      if (pending) {
        return this.$store.dispatch('tryToFetchNeopayPaymentData', {
          token: data,
          onPaymentSuccess,
          onPaymentError,
        });
      }

      if (canceled) {
        this.isLoading = false;
        this.navigateToOrder();
        return;
      }

      if (token) {
        return this.$store.dispatch('tryToFetchNeopayPaymentData', {
          token,
          onPaymentSuccess,
          onPaymentError,
        });
      }
    },
    goToRequestInvoice() {
      this.$router.push({
        name: 'RequestInvoice',
        params: {
          paymentUuid: this.paymentUuid,
        },
      });
    },
    goBack() {
      const origin = this.$route.query.origin;

      if (origin === 'PaymentHistory') {
        this.$router.go(-1);
      } else {
        this.navigateToOrder();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background-color: $color-background-gray;
  height: 100%;
}

.success-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.green-text {
  color: #0e3d02;
}
</style>
