<template>
  <div
    v-if="displaySocialLinks"
    class="d-flex flex-row align-center justify-center mt-4"
  >
    <div
      v-for="(link, index) in normalizedSocialLinks"
      :key="index"
      :style="{ width: link.size, height: link.size }"
      class="social-item"
    >
      <a
        :data-cy="`social-link-${link.name}`"
        :href="link.link"
        target="_blank"
        rel="noopener noreferrer"
        :style="{ color: link.color }"
      >
        <SocialIcons :name="link.name" />
      </a>
    </div>
  </div>
</template>

<script>
import SocialIcons from '@/assets/icons/SocialIcons.vue';

export default {
  components: {
    SocialIcons,
  },
  props: {
    socialLinks: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    displaySocialLinks() {
      return this.socialLinks.length > 0;
    },
    normalizedSocialLinks() {
      if (!this.socialLinks.length) return [];

      return this.socialLinks.map(link => {
        return {
          ...link,
          size: link.size ? `${link.size}px` : '24px',
          color: link.color || 'black',
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.social-item {
  &:first-child {
    margin-left: 0;
  }
  margin-left: 20px;
}
</style>
