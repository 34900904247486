<template>
  <div class="d-flex align-center">
    <v-divider color="#F5F5F5" />
    <div class="mx-3 tp-text-label" :class="textClass">
      {{ text }}
      <slot></slot>
    </div>
    <v-divider color="#F5F5F5" />
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: false,
      default: '',
    },
    textClass: {
      type: String,
      required: false,
      default: 'grey--text text--darken-1',
    },
  },
};
</script>

<style></style>
