import Vue from 'vue';

import formatPriceWithCurrency from '../../../helpers/formatPriceWithCurrency';
import { getCartOptionByMenuItemAndOptionId } from './helpers';
import { placeOrderRequest, updateCartItemCoursesRequest } from './api';

// TODO think of splitting this into separate files

const initialState = {
  items: [],
  tipAmount: null,
  discount: {
    code: null,
    status: 'idle',
    errorMessages: null,
    amount: null,
    type: null,
  },
  orderComment: null,
  extraFees: [],
};

export default {
  state: initialState,
  actions: {
    clearCart(context) {
      context.commit('CLEAR_CART');
      context.commit('CLEAR_DISCOUNT');
    },
    clearDiscount(context) {
      context.commit('CLEAR_DISCOUNT');
    },
    setCart(context, cart) {
      context.commit('SET_CART', cart);
    },
    setExtraFees(context, extraFees) {
      context.commit('SET_EXTRA_FEES', extraFees);
    },
    setOrderComment(context, comment) {
      context.commit('SET_ORDER_COMMENT', comment);
    },
    clearOrderComment(context) {
      context.commit('SET_ORDER_COMMENT', null);
    },
    setDiscountCode(context, discountCode) {
      context.commit('SET_DISCOUNT_CODE', discountCode.toUpperCase());
    },
    async addCartItem(
      { rootState, dispatch },
      { quantity, optionGroups, menuItemId, variationId }
    ) {
      const { session } = rootState;

      try {
        await Vue.axios.post(`/cart/${session}/add`, {
          menu_item_id: menuItemId,
          quantity,
          option_groups: optionGroups,
          variation_id: variationId,
        });
        dispatch('sendAnalyticsEvent', {
          event: 'add_cart_item',
          menu_item_id: menuItemId,
        });
      } catch (error) {
        if (error.response.data.message === 'Menu item unavailable') {
          dispatch('setToastData', {
            title: Vue.i18n.translate(
              'components.toast.menuItemUnavailableTitle'
            ),
            description: Vue.i18n.translate(
              'components.toast.menuItemUnavailableDescription'
            ),
            action: () => {
              location.reload();
            },
            isError: true,
            doNotClose: true,
          });
          dispatch('openToast');
          return false;
        } else if (error.response.data.message === 'Not enough item stock') {
          dispatch('setToastData', {
            title: Vue.i18n.translate(
              'components.toast.menuItemNotEnoughStockTitle'
            ),
            description: Vue.i18n.translate(
              'components.toast.menuItemNotEnoughStockDescription',
              { leftInStock: error.response.data.left_in_stock }
            ),
            isError: true,
          });
          dispatch('openToast');
          return false;
        } else if (
          error.response.data.message === 'Restaurant is not accepting orders'
        ) {
          dispatch('setToastData', {
            title: Vue.i18n.translate(
              'components.toast.restaurantNotAcceptingOrdersTitle'
            ),
            description: Vue.i18n.translate(
              'components.toast.restaurantNotAcceptingOrdersDescription'
            ),
            action: () => {
              location.reload();
            },
            isError: true,
            doNotClose: true,
          });
          dispatch('openToast');
          return;
        } else {
          dispatch('setToastData', {
            title: Vue.i18n.translate('generic.serverErrorTitle'),
            description: Vue.i18n.translate('generic.serverErrorDescription'),
            isError: true,
            action: () => {
              location.reload();
            },
          });
          dispatch('openToast');
        }
        throw error;
      }

      await dispatch('fetchCart');
    },
    async removeMenuItemFromCart({ rootState, dispatch }, menuItemId) {
      try {
        const { session } = rootState;
        await Vue.axios.post(`/cart/${session}/remove-menu-item`, {
          menu_item_id: menuItemId,
        });
        dispatch('sendAnalyticsEvent', {
          event: 'remove_cart_item',
          menu_item_id: menuItemId,
        });
        await dispatch('fetchCart');
      } catch (error) {
        console.error(error);
      }
    },
    async changeCartItemQuantity({ getters }, { cartItemId, quantity }) {
      const cartItem = getters.cartItemByCartItemId(cartItemId);
      cartItem.quantity = quantity;
    },
    async reapplyDiscountIfNeeded({ getters, dispatch }) {
      const discountCode = getters.discountCode;
      const discountAmount = getters.discountAmount;

      if (discountCode && discountAmount) {
        await dispatch('applyDiscount');
      }

      return;
    },
    async applyDiscount(
      { rootState, commit, dispatch },
      { fetchCart = false } = {}
    ) {
      commit('SET_DISCOUNT_STATUS', 'loading');
      commit('SET_DISCOUNT_ERROR_MESSAGE', null);
      try {
        const { session } = rootState;
        const response = await Vue.axios.post(
          `/cart/${session}/apply-discount`,
          {
            code: rootState.cart.discount.code,
          }
        );
        const { error } = response.data;

        commit('SET_DISCOUNT_STATUS', 'idle');
        commit('SET_DISCOUNT_AMOUNT', response.data.discount);
        commit('SET_DISCOUNT_TYPE', response.data.coupon_type);
        commit('SET_DISCOUNT_ERROR_MESSAGE', error);

        if (!error && fetchCart) {
          dispatch('fetchCart');
        }
      } catch (error) {
        commit('SET_DISCOUNT_STATUS', 'idle');
        commit('SET_DISCOUNT_AMOUNT', null);
        commit('SET_DISCOUNT_TYPE', null);
        if (error?.response?.data?.message === 'Coupon not found') {
          commit(
            'SET_DISCOUNT_ERROR_MESSAGE',
            Vue.i18n.translate('components.discountCode.couponNotFound')
          );
        } else if (
          error?.response?.data?.message === 'Coupon discount exceeds subtotal'
        ) {
          commit(
            'SET_DISCOUNT_ERROR_MESSAGE',
            Vue.i18n.translate('components.discountCode.couponExceedsLimit')
          );
        } else {
          commit('SET_DISCOUNT_ERROR_MESSAGE', 'Server error');
        }
      }
    },
    async removeDiscount({ rootState, commit }) {
      commit('SET_DISCOUNT_STATUS', 'loading');
      commit('SET_DISCOUNT_ERROR_MESSAGE', null);
      try {
        const { session } = rootState;
        const response = await Vue.axios.get(
          `/cart/${session}/remove-discount`
        );
        const { error } = response.data;

        commit('SET_DISCOUNT_CODE', null);
        commit('SET_DISCOUNT_STATUS', 'idle');
        commit('SET_DISCOUNT_AMOUNT', null);
        commit('SET_DISCOUNT_TYPE', null);
        commit('SET_DISCOUNT_ERROR_MESSAGE', error);
      } catch (error) {
        commit('SET_DISCOUNT_STATUS', 'idle');
        commit('SET_DISCOUNT_AMOUNT', null);
        commit('SET_DISCOUNT_TYPE', null);
        commit('SET_DISCOUNT_ERROR_MESSAGE', 'Server error');
      }
    },
    setTipAmount(context, amount) {
      context.commit('SET_TIP_AMOUNT', amount);
    },
    async fetchCart({ rootState, getters, dispatch }) {
      const functionalityOrderEnabled = getters.functionalityOrderEnabled;
      const ordersTemporarilyDisabled = getters.ordersTemporarilyDisabled;

      if (!functionalityOrderEnabled || ordersTemporarilyDisabled) {
        return;
      }

      await dispatch('reapplyDiscountIfNeeded');

      const { session } = rootState;
      const discountAmount = getters.discountAmount;

      const response = (await Vue.axios.get(`/cart/${session}/get`)).data;
      if (response.error === 'Menu items unavailable') {
        dispatch('setToastData', {
          title: Vue.i18n.translate(
            'components.toast.placeOrderItemsUnavailableTitle'
          ),
          description: Vue.i18n.translate(
            'components.toast.placeOrderItemsUnavailableDescription'
          ),
          action: () => {
            location.reload();
          },
          isError: true,
          doNotClose: true,
        });
        dispatch('openToast');
      }

      await dispatch('setCart', response.cart);
      dispatch('setExtraFees', response.extra_fees);

      if (response.coupon && !discountAmount) {
        dispatch('setDiscountCode', response.coupon);
        dispatch('applyDiscount');
      } else if (response.coupon === null) {
        dispatch('clearDiscount');
      }
    },
    async placeOrder(
      { getters, dispatch, rootState },
      { comment, tipAmount = 0, details = {}, onSuccess, onError }
    ) {
      try {
        const { session } = rootState;

        const order = await placeOrderRequest({
          session,
          comment,
          tipAmount,
          firebase_uid: getters.userId,
          details,
        });

        if (onSuccess) {
          onSuccess(order);
        }
        dispatch('clearOrderComment');

        return order;
      } catch (error) {
        if (onError) {
          onError(error);
        }
        dispatch('handlePlaceOrderError', error);
        throw error;
      }
    },
    async handlePlaceOrderError({ dispatch, getters }, error) {
      if (error.response.data.message === 'Menu item unavailable') {
        dispatch('setToastData', {
          title: Vue.i18n.translate(
            'components.toast.placeOrderItemsUnavailableTitle'
          ),
          description: Vue.i18n.translate(
            'components.toast.placeOrderItemsUnavailableDescription'
          ),
          action: () => {
            location.reload();
          },
          isError: true,
          doNotClose: true,
        });
        dispatch('openToast');
      } else if (
        error.response.data.message === 'Restaurant is not accepting orders'
      ) {
        dispatch('setToastData', {
          title: Vue.i18n.translate(
            'components.toast.restaurantNotAcceptingOrdersTitle'
          ),
          description: Vue.i18n.translate(
            'components.toast.restaurantNotAcceptingOrdersDescription'
          ),
          action: () => {
            location.reload();
          },
          isError: true,
          doNotClose: true,
        });
        dispatch('openToast');
      } else if (error.response.data.message === 'Coupon not found') {
        dispatch('setToastData', {
          title: Vue.i18n.translate('components.toast.couponNotFoundTitle'),
          description: Vue.i18n.translate(
            'components.toast.couponNotFoundDescription'
          ),
          action: () => {
            location.reload();
          },
          isError: true,
          doNotClose: true,
        });
        dispatch('openToast');
        dispatch('clearDiscount');
      } else if (
        error.response.data.message === 'Total is less than 50 cents'
      ) {
        const minAmount = getters.getFormattedPrice(0.5);
        dispatch('setToastData', {
          title: Vue.i18n.translate('components.toast.totalTooSmallTitle'),
          description: Vue.i18n.translate(
            'components.toast.totalTooSmallDescription',
            { amount: minAmount }
          ),
          action: () => {
            location.reload();
          },
          isError: true,
          doNotClose: true,
        });
        dispatch('openToast');
      } else if (error.response.data.message === 'Table is already taken') {
        dispatch('setToastData', {
          title: Vue.i18n.translate('components.toast.tableTakenTitle'),
          description: Vue.i18n.translate(
            'components.toast.tableTakenDescription'
          ),
          isError: true,
          doNotClose: true,
        });
        dispatch('openToast');
      } else if (error.response.data.message === 'Terminal is offline') {
        dispatch('setToastData', {
          title: Vue.i18n.translate('components.toast.terminalIsOfflineTitle'),
          description: Vue.i18n.translate(
            'components.toast.terminalIsOfflineDescription'
          ),
          isError: true,
          doNotClose: true,
        });
        dispatch('openToast');
      } else if (error.response.data.message === 'Card is not valid') {
        dispatch('setToastData', {
          title: Vue.i18n.translate('components.toast.cardNotValidTitle'),
          description: Vue.i18n.translate(
            'components.toast.cardNotValidDescription'
          ),
          action: () => {
            location.reload();
          },
          isError: true,
          doNotClose: true,
        });
        dispatch('openToast');
      }
    },
    async updateCartItemCourse({ getters }, { cartItemId, courseId }) {
      const cartItem = getters.cartItemByCartItemId(cartItemId);
      cartItem.course_id = courseId;
    },
    async updateCartItemsCourses({ rootState }, { items }) {
      const { session } = rootState;
      try {
        updateCartItemCoursesRequest({
          session,
          items,
        });
      } catch (error) {
        console.error(error);
      }
    },
  },
  mutations: {
    SET_CART(state, cart) {
      state.items = cart;
    },
    SET_EXTRA_FEES(state, extraFees) {
      state.extraFees = extraFees;
    },
    CLEAR_CART(state) {
      state.items = [];
      state.tipAmount = null;
    },
    CLEAR_DISCOUNT(state) {
      state.discount.code = null;
      state.discount.status = 'idle';
      state.discount.errorMessages = null;
      state.discount.amount = null;
      state.discount.type = null;
    },
    SET_TIP_AMOUNT(state, amount) {
      state.tipAmount = amount;
    },
    SET_DISCOUNT_CODE(state, discountCode) {
      state.discount.code = discountCode;
    },
    SET_DISCOUNT_STATUS(state, status) {
      state.discount.status = status;
    },
    SET_DISCOUNT_AMOUNT(state, amount) {
      state.discount.amount = amount;
    },
    SET_DISCOUNT_TYPE(state, type) {
      state.discount.type = type;
    },
    SET_DISCOUNT_ERROR_MESSAGE(state, messages) {
      state.discount.errorMessages = messages;
    },
    SET_ORDER_COMMENT(state, comment) {
      state.orderComment = comment;
    },
  },
  getters: {
    hasCartItems(state) {
      return state.items?.length > 0;
    },
    cartItems(state) {
      return state.items;
    },
    cartItemById: state => id => {
      return state.items.find(item => {
        return item.item_id === id;
      });
    },
    cartItemByCartItemId: state => id => {
      return state.items.find(item => {
        return item.cart_item_id === id;
      });
    },
    tipAmount(state) {
      return state.tipAmount;
    },
    orderComment(state) {
      return state.orderComment;
    },
    cartCount: function(state) {
      return state.items.reduce((acc, orderItem) => {
        return acc + orderItem.quantity;
      }, 0);
    },
    normalizedCartItems(_, getters, rootState) {
      const cartItems = getters.cartItems;

      if (!cartItems) {
        return [];
      }
      return (
        cartItems.map(cartItem => {
          const menuItem = rootState.items.find(item => {
            return item.id === cartItem.item_id;
          });

          const getPrice = aCartItem => {
            if (aCartItem.variation_id) {
              const variation = menuItem.variations.find(
                variation => variation.id === aCartItem.variation_id
              );

              return variation.price;
            }

            if (menuItem.discounted_price !== false) {
              return menuItem.discounted_price;
            }
            return menuItem.price;
          };

          return {
            id: cartItem.cart_item_id,
            name: menuItem.name,
            quantity: cartItem.quantity,
            modifiers: cartItem.options.map(option => {
              return {
                ...getCartOptionByMenuItemAndOptionId(
                  menuItem,
                  option.option_id
                ),
                quantity: option.quantity,
              };
            }),
            courseId: cartItem.course_id,
            loyalty_price: menuItem.loyalty_price,
            price: getPrice(cartItem),
            variation: menuItem.variations.find(
              variation => variation.id === cartItem.variation_id
            ),
          };
        }) || []
      );
    },
    normalizedCartItemsByCourse(_, getters) {
      const normalizedCartItems = getters.normalizedCartItems;
      const courses = getters.courses;

      return courses.map((course, index) => {
        if (index === 0) {
          return {
            ...course,
            items: normalizedCartItems.filter(item => {
              return item.courseId === course.id || item.courseId === null;
            }),
          };
        }

        return {
          ...course,
          items: normalizedCartItems.filter(item => {
            return item.courseId === course.id;
          }),
        };
      });
    },
    cartAmount: function(_, getters) {
      const normalizedCartItems = getters.normalizedCartItems; // important to use normalizedCartItems here
      let cartAmount = 0;
      normalizedCartItems.forEach(item => {
        cartAmount += item.price * item.quantity;
        item.modifiers.forEach(option => {
          cartAmount += option.markup * option.quantity * item.quantity;
        });
      });

      return cartAmount;
    },
    cartAmountFormatted: function(_, getters, rootState) {
      return formatPriceWithCurrency(
        getters.cartAmount,
        rootState.currency.code,
        rootState.locale.locale_code
      );
    },
    // Actual amount of the cart, including tip, discount, small order fee and loyalty discount
    cartSubtotalAmount: function(_, getters) {
      const subtotal = getters.cartAmount;
      const tip = getters.tipAmount;
      const discount = getters.discountAmount;
      const smallOrderFee = getters.cartSmallOrderFeeAmount;
      const loyaltyDiscount = getters.loyaltyDiscountAmount;

      let amount = subtotal;

      if (tip) {
        amount += tip;
      }

      if (discount) {
        amount -= discount;
      }

      if (smallOrderFee) {
        amount += smallOrderFee;
      }

      if (loyaltyDiscount) {
        amount -= loyaltyDiscount;
      }

      return amount;
    },
    cartSubtotalPrice(_, getters, rootState) {
      return formatPriceWithCurrency(
        getters.cartSubtotalAmount,
        rootState.currency.code,
        rootState.locale.locale_code
      );
    },
    cartSmallOrderFeeAmount(_, getters) {
      const smallOrderFeeThreshold = 0.5;
      const discountAmount = getters.discountAmount;
      const cartSubtotalAmount = getters.cartAmount;
      const total = cartSubtotalAmount - discountAmount;

      function round(num, decimalPlaces = 0) {
        var p = Math.pow(10, decimalPlaces);
        var n = num * p * (1 + Number.EPSILON);
        return Math.round(n) / p;
      }

      if (total < smallOrderFeeThreshold && total !== 0) {
        return round(smallOrderFeeThreshold - total, 2);
      }

      return null;
    },
    isNegativeCartAmount(_, getters) {
      return getters.cartSubtotalAmount < 0;
    },
    hasFullDiscount(_, getters) {
      return (
        getters.discountAmount &&
        getters.cartAmount === getters.discountAmount &&
        getters.cartSubtotalAmount === 0 // TODO change naming of cartSubtotalAmount into cartTotalAmount
      );
    },
    cartExtraFees(state) {
      return state.extraFees;
    },
    loadingDiscount(state) {
      return state.discount.status === 'loading';
    },
    discountAmount(state) {
      return state.discount.amount;
    },
    discountType(state) {
      return state.discount.type;
    },
    discountErrorMessages(state) {
      return state.discount.errorMessages;
    },
    discountCode(state) {
      return state.discount.code;
    },
    isDiscountTypePayAtRestaurant(state) {
      return state.discount.type === 'pay-at-restaurant';
    },
    potentialDiscountAmount(_, getters) {
      const cartAmount = getters.cartAmount;
      const cartAmountWithLoyaltyPricing = getters.cartAmountWithLoyaltyPricing;

      return cartAmount - cartAmountWithLoyaltyPricing;
    },
    loyaltyDiscountAmount(_, getters) {
      const loyaltyProgram = getters.enrolledRestaurantLoyaltyProgram;

      if (!loyaltyProgram) {
        return null;
      }

      return getters.potentialDiscountAmount;
    },
    cartAmountWithLoyaltyPricing: function(_, getters) {
      const normalizedCartItems = getters.normalizedCartItems; // important to use normalizedCartItems here

      const getItemPrice = item => {
        if (item.variation) {
          return item.variation.loyalty_price || item.variation.price;
        }

        return item.loyalty_price || item.price;
      };

      let cartAmount = 0;
      normalizedCartItems.forEach(item => {
        cartAmount += getItemPrice(item) * item.quantity;
        item.modifiers.forEach(option => {
          cartAmount +=
            (option?.loyalty_markup || option.markup) *
            option.quantity *
            item.quantity;
        });
      });

      return cartAmount;
    },
    cartAmountWithLoyaltyPricingFormatted: function(_, getters, rootState) {
      return formatPriceWithCurrency(
        getters.cartAmountWithLoyaltyPricing,
        rootState.currency.code,
        rootState.locale.locale_code
      );
    },
  },
};
