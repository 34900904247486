<template>
  <div class="tip-success-container">
    <EasyWithGreet
      v-if="!isLoading"
      style="position: absolute; top: 32px; left: 50%; transform: translate(-50%, -50%);"
    />
    <div class="close-button">
      <v-icon
        v-if="!sessionToken && !isLoading"
        color="white"
        data-cy="tip-success-close"
        @click="returnBackToTipWaiter"
      >
        mdi-close
      </v-icon>
    </div>
    <div v-if="isLoading" class="tip-success-content">
      <v-progress-circular indeterminate color="white" />
    </div>
    <div v-else class="tip-success-content pa-8">
      <div v-if="failedPayment">
        <h3 class="pt-4">
          {{ $t('screens.tipSuccess.failed.title') }}
        </h3>
        {{ $t('screens.tipSuccess.failed.subtitle') }}
      </div>
      <div v-else class="w-100">
        <div>
          <Avatar
            :photo-url="tipsRecipient.photo"
            :name="tipsRecipient.name"
            :lazy-src="tipsRecipient.lazySrc"
            :on-click="emojiRain"
            :size="150"
          />
        </div>
        <h3 class="pt-4" data-cy="tip-success-name">
          {{ tipsRecipient.name }}
        </h3>
        <div class="pb-6">
          <span data-cy="tip-success-tip-amount">
            {{ $t('screens.tipSuccess.title', { amount: amountFormatted }) }}
          </span>
        </div>
        <Button
          v-if="showTankYou"
          white
          class="mt-4"
          data-cy="tip-success-view-thank-you"
          @click="toggleTankYouModal"
        >
          {{ $t('screens.tipSuccess.viewThankYouCta') }}
        </Button>
        <Button v-if="sessionToken" white class="mt-4" @click="goToMenu">
          {{ $t('screens.tipSuccess.backCta') }}
        </Button>
        <ReviewDrawer v-if="showReviewCta" :tips-qr-card-hash="tipsQrCardHash">
          <template v-slot:activator="{ toggle }">
            <Button
              data-cy="tip-success-leave-review"
              class="mt-4 menu-splash-screen-pay-go-infos-ctas__button"
              @click="toggle"
            >
              {{ $t('components.review.leaveReviewCta') }}
            </Button>
          </template>
        </ReviewDrawer>
        <ReceiptBottomSheet
          :payment-uuid="paymentUuid"
          session-token="sessionToken"
        />
      </div>
    </div>
    <Modal
      :is-open="showTankYouModal"
      :close-label="$t('generic.close')"
      @close-click="toggleTankYouModal"
    >
      <template slot="content">
        <div class="d-flex flex-column justify-center text-center">
          <h4 class="pb-5">{{ $t('screens.tipSuccess.thankYouTitle') }}</h4>
          <div
            v-if="tipsRecipient?.success_gif"
            class="d-flex justify-center pb-5"
            data-cy="tip-success-personnel-gif"
          >
            <v-img
              max-width="300"
              contain
              class="rounded-lg"
              :src="tipsRecipient?.success_gif"
              :alt="tipsRecipient?.name"
            />
          </div>
          <p
            v-if="tipsRecipient?.success_description"
            class="d-flex justify-center tp-text-body"
            data-cy="tip-success-personnel-thank-you-message"
          >
            {{ tipsRecipient.success_description }}
          </p>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { emojisplosion, emojisplosions } from 'emojisplosion';

import EasyWithGreet from '@/components/EasyWithGreet.vue';
import ReviewDrawer from '@/components/Reviews/ReviewDrawer.vue';
import Button from '@/components/Button.vue';
import Avatar from '@/components/common/Avatar.vue';
import Modal from '@/components/common/Modal.vue';
import ReceiptBottomSheet from '@/components/common/ReceiptBottomSheet.vue';
import { parseQueryParam } from '@/utils/strings';

const physics = {
  fontSize: {
    min: 34,
    max: 44,
  },
};

const moneyEmojis = ['💸', '💶', '💰', '🤑', '🫰', '📈', '♥️'];

export default {
  components: {
    Button,
    Avatar,
    Modal,
    EasyWithGreet,
    ReviewDrawer,
    ReceiptBottomSheet,
  },
  data() {
    return {
      isLoading: true,
      stripe: null,
      tipsQrCardHash: null,
      orderId: null,
      sessionToken: null,
      amount: null,
      showTankYouModal: false,
      failedPayment: false,
      paymentUuid: null,
    };
  },
  computed: {
    tipsRecipient() {
      return this.$store.getters.tipsRecipientData;
    },
    amountFormatted() {
      return this.$store.getters.getFormattedPrice(this.amount);
    },
    showTankYou() {
      return (
        this.tipsRecipient?.success_description ||
        this.tipsRecipient?.success_gif
      );
    },
    showReviewCta() {
      return this.$store.getters.showReviewInSummaryScreen;
    },
  },
  async mounted() {
    this.isLoading = true;
    const query = this.$route.query;

    const tipsQrCardHash = this.$route.params.tipsQrCardHash;
    const orderId = parseQueryParam(query.orderId);
    const sessionToken = parseQueryParam(query.sessionToken);
    const personnelId = parseQueryParam(query.personnel_id);
    const paymentUuid = parseQueryParam(query.paymentUuid);

    this.tipsQrCardHash = tipsQrCardHash;
    this.orderId = orderId;
    this.sessionToken = sessionToken;
    this.paymentUuid = paymentUuid;

    if (sessionToken && sessionToken !== 'undefined') {
      await this.$store.dispatch('fetchSessionData', {
        session: this.sessionToken,
      });
    }

    await this.$store.dispatch('fetchTipsData', {
      tipsQrCardHash,
      personnelId,
    });
    await this.loadStripeIfNeeded();
    await this.fetchPaymentDetails();

    this.isLoading = false;
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    goToMenu() {
      this.$router.replace({
        name: 'Menu',
        params: {
          session: this.sessionToken,
        },
      });
    },
    async loadStripeIfNeeded() {
      if (!this.stripe) {
        this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_PK || '');
      }
    },
    async checkNeoPayPaymentStatus(token) {
      return this.$store.dispatch('checkNeoPayPaymentStatus', {
        token,
      });
    },
    moneyEmojiExplosion() {
      const { cancel } = emojisplosions({
        emojis: moneyEmojis,
        emojiCount: 6,
        interval: 300,
        physics,
      });

      setTimeout(cancel, 1500);
    },
    returnBackToTipWaiter() {
      this.$router.replace({
        name: 'TipWaiter',
        params: {
          tipsQrCardHash: this.tipsQrCardHash,
        },
        query: {
          orderId: this.orderId,
          sessionToken: this.sessionToken,
        },
      });
    },
    async tryToFetchNeopayPaymentData(token) {
      let retryAttempts = 0;
      const retryAttemptsLimit = 20;
      const retryInterval = 10000;

      while (retryAttempts < retryAttemptsLimit) {
        const { amount, uuid } = await this.checkNeoPayPaymentStatus(token);

        if (amount) {
          this.amount = amount;
          this.paymentUuid = uuid;
          this.moneyEmojiExplosion();
          return;
        }

        retryAttempts += 1;

        await new Promise(resolve => setTimeout(resolve, retryInterval));
      }

      this.failedPayment = true;
      this.isLoading = false;
      return;
    },
    async fetchNeoPayPaymentData() {
      const token = this.$route.query.token;
      const data = this.$route.query.data;
      const canceled = this.$route.query.canceled === '1';
      const pending = this.$route.query.pending === '1';

      if (pending) {
        return this.tryToFetchNeopayPaymentData(data);
      }

      if (canceled) {
        this.isLoading = false;
        this.returnBackToTipWaiter();
        return;
      }

      if (token) {
        return this.tryToFetchNeopayPaymentData(token);
      }
    },
    async fetchPaymentDetails() {
      const isNeopay = this.$route.query['neopay-callback'] === '1';

      if (isNeopay) {
        await this.fetchNeoPayPaymentData();
        return;
      }

      try {
        const paymentDetailsResponse = await this.axios.get(
          `/payments/payment-by-uuid/${this.paymentUuid}`
        );
        const { amount } = paymentDetailsResponse.data.data;

        this.amount = amount;
        this.moneyEmojiExplosion();
      } catch (error) {
        console.error(error);
        this.isLoading = false;
      }
    },
    toggleTankYouModal() {
      this.showTankYouModal = !this.showTankYouModal;
    },
    emojiRain() {
      emojisplosion({
        emojis: moneyEmojis,
        emojiCount: 7,
        physics,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tip-success-container {
  height: 100dvh;
  background-color: var(--color-primary);
}

.tip-success-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
}

.close-button {
  position: absolute;
  top: 28px;
  right: 28px;
  z-index: 10;
}

.social-links-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 56px;
}

.social-link {
  padding: 11px;
  margin-left: 11px;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 10px;
}
</style>
