<template>
  <div class="top-buttons">
    <div>
      <GreetDrawer v-show="showLogo">
        <template #activator="{ toggle }">
          <v-btn depressed rounded class="history-icon" @click="toggle">
            <GreetLogo :width="65" color="black" />
          </v-btn>
        </template>
      </GreetDrawer>
    </div>
    <div class="right-buttons">
      <v-btn
        v-if="showProfileButton"
        fab
        small
        depressed
        @click="navigateToProfile"
      >
        <v-avatar :size="36" data-cy="login">
          <img v-if="userPhotoUrl" :src="userPhotoUrl" :alt="user?.email" />
          <v-icon v-else color="#5F5F5F">
            mdi-account-circle
          </v-icon>
        </v-avatar>
      </v-btn>
      <v-btn
        v-if="showPaymentHistoryButton && !showLogo"
        depressed
        rounded
        class="history-icon"
        data-cy="payment-history-button"
        @click="navigateToPaymentHistory"
      >
        <v-icon left color="black">
          mdi-history
        </v-icon>
        <div class="tp-text-body-2 tp-normal-spacing">
          {{ $t('screens.selectScreen.paymentsCta') }}
        </div>
      </v-btn>
      <v-btn
        v-if="$store.getters.restaurantHasLanguages"
        fab
        small
        icon
        class="language-icon"
        @click="toggleLanguageSelector"
      >
        <img
          :src="getLanguageImageByCode($i18n.locale())"
          style="width: 22px"
        />
      </v-btn>
    </div>
  </div>
</template>

<script>
import GreetLogo from '@/components/GreetLogo.vue';
import GreetDrawer from '@/components/SplashScreen/GreetDrawer.vue';

export default {
  components: {
    GreetLogo,
    GreetDrawer,
  },
  props: {
    showLogo: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showPaymentHistoryButton() {
      return this.$store.getters.functionalityPayGoEnabled;
    },
    currentRestaurantId() {
      return this.$store.getters.getCurrentRestaurantId;
    },
    restaurantLoyaltyProgram() {
      return this.$store.getters.restaurantLoyaltyProgram();
    },
    loyaltyProgramEnabled() {
      return this.$store.getters.functionalityLoyaltyProgramEnabled;
    },
    showProfileButton() {
      return this.loyaltyProgramEnabled && this.restaurantLoyaltyProgram;
    },
    userPhotoUrl() {
      return this.$store.getters.userProfilePhoto;
    },
  },
  methods: {
    getLanguageImageByCode: function(code) {
      return require(`@/assets/images/flags/${code}.svg`);
    },
    toggleLanguageSelector: function() {
      this.$store.dispatch('openLanguageSelector');
      this.$store.dispatch('sendAnalyticsEvent', {
        event: 'language_selector_opened',
      });
    },
    navigateToPaymentHistory: function() {
      this.$router.push({ name: 'PaymentHistory' });
    },
    navigateToProfile: function() {
      this.$router.push({
        name: 'Profile',
        params: {
          session: this.$store.state.session,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.top-buttons {
  z-index: 150 !important;
  position: fixed;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  flex-direction: row;
  width: 100%;
  gap: 10px;
  @media screen and (min-width: $max-app-width) {
    max-width: $max-app-width;
    margin: 0 auto;
  }
}

.right-buttons {
  display: flex;
  gap: 10px;
}

.history-icon {
  height: 36px !important;
}

.language-icon {
  background: rgba(255, 255, 255, 0.85) !important;
  box-shadow: none !important;
  width: 36px !important;
  height: 36px !important;
}
</style>
