import Vue from 'vue';
import './plugins/axios';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import VueGtm from '@gtm-support/vue2-gtm';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import moment from 'vue-moment';

import VCurrencyField from 'v-currency-field';

import translationsLt from './i18n/lt';
require('./plugins/replaceAllPolyfill.js');

import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

import LottieVuePlayer from '@lottiefiles/vue-lottie-player';

import { initializeApp } from 'firebase/app';

Vue.config.productionTip = false;

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
initializeApp(firebaseConfig);

if (process.env.VUE_APP_ENVIRONMENT === 'production') {
  Vue.use(VueGtm, {
    id: 'GTM-MBPBCQ5',
    enabled: false,
  });
}

Vue.mixin({
  data: function() {
    return {
      maxAppWidth: 600,
      tipsColorGlobal: '#E85941',
      donateColorGlobal: '#E85941',
      greetInstagram: 'https://www.instagram.com/greet.app',
      greetFacebook: 'https://www.facebook.com/greet.menu',
      greetWebsite: 'https://greet.lt',
    };
  },
});

Vue.component('vue-phone-number-input', VuePhoneNumberInput);
Vue.use(LottieVuePlayer);
Vue.use(VCurrencyField, {
  decimalLength: 2,
  autoDecimalMode: false,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: false,
});

Vue.use(moment);

// add translations directly to the application
Vue.i18n.add('lt', translationsLt);
// set the start locale to use
Vue.i18n.set('lt');

if (process.env.VUE_APP_ENVIRONMENT === 'production') {
  Sentry.init({
    Vue,
    dsn:
      'https://0e4771fb0c0343f38cd99a56f3366523@o4504293931024384.ingest.sentry.io/4504293933711360',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ['app.greet.menu', /^\//],
      })
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.3,
  });
}

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
}).$mount('#app');
