<template>
  <div
    :class="headless ? 'order-info-headless' : 'order-info'"
    data-cy="order-info"
  >
    <LayoutCard :class="{ 'pt-7': addTopPadding }">
      <div v-if="!headless" class="order-info__close-btn-wrapper">
        <img
          class="order-info__close-btn"
          :src="require(`@/assets/images/icons/close-item-icon.svg`)"
          @click="close"
        />
      </div>
      <OrderStatus
        :status="status"
        :items="getItems"
        :delivery-time="getDeliveryTime"
        :order-id="order_id"
        :pay-at-restaurant="pay_at_restaurant"
        :order-seen="seen"
        :batch="batch"
        :sending-to-pos="sending_to_pos"
        :sent-to-pos="sent_to_pos"
        :unique-order-number="unique_order_number"
      />
      <div
        v-if="sessionPhoneNumber && !orderStatusDelivered"
        class="tp-text-body text-center"
      >
        {{ $t('screens.order.smsDisclaimer') }}
      </div>
    </LayoutCard>
    <LayoutCard
      v-if="$store.getters.customizationOrderInfoAdditionalSection1Title"
      :title="$t($store.getters.customizationOrderInfoAdditionalSection1Title)"
    >
      <a
        target="_blank"
        :href="$store.getters.customizationOrderInfoAdditionalSection1ImageUrl"
      >
        <v-img
          v-if="$store.getters.customizationOrderInfoAdditionalSection1ImageUrl"
          :src="$store.getters.customizationOrderInfoAdditionalSection1ImageUrl"
          contain
        />
      </a>
      <div
        v-if="$store.getters.customizationOrderInfoAdditionalSection1Text"
        class="tp-text-body"
        :class="{
          'mt-3':
            $store.getters.customizationOrderInfoAdditionalSection1ImageUrl,
        }"
      >
        {{ $t($store.getters.customizationOrderInfoAdditionalSection1Text) }}
      </div>
    </LayoutCard>
    <LayoutCard v-if="showReviewsTop" rounded>
      <LeaveReview
        :unique-order-number="unique_order_number"
        :review="review"
      />
    </LayoutCard>
    <LayoutCard
      v-if="
        $store.getters.functionalitySkipPaymentEnabled &&
          $store.state.restaurant.type === 'hotel'
      "
    >
      <PaymentInformationHotel :subtotal="subtotal"></PaymentInformationHotel>
    </LayoutCard>
    <PersonnelPicker
      v-if="!this.$store.getters.functionalityCollectiveTipsEnabled"
      :order-id="unique_order_number"
      :session-token="this.$route.params.session"
    />
    <NotificationBanner
      :is-open="showAllowNotificationsBanner && !orderStatusDelivered"
      @cta-click="showAllowNotificationsModal = true"
    />
    <LayoutCard
      v-if="!orderStatusFailed"
      :title="`${$t('screens.order.orderListTitle')} #${order_id}`"
    >
      <div class="order-info-summary__items">
        <div
          v-for="(item, key) in getItems"
          :key="key"
          class="order-info-summary-items__item"
        >
          <div class="order-info-summary-items-item__name-wrapper">
            <div
              class="order-info-summary-items-item__quantity tp-text-body mr-3"
              :class="{ delivered: item.is_delivered || orderStatusDelivered }"
            >
              1
            </div>
            <div class="tp-text-body">
              {{ $t(item.name) }}
              <div
                v-for="(modifier, index) in item.modifiers || []"
                :key="`${modifier}-${index}`"
                class="tp-text-body-gray"
              >
                {{ $t(modifier.name) }}
              </div>
            </div>
          </div>
          <div class="tp-text-body">{{ formatPrice(item.totalPrice) }}</div>
        </div>
      </div>
    </LayoutCard>
    <LayoutCard
      v-if="
        (!$store.getters.functionalitySkipPaymentEnabled ||
          $store.state.restaurant.type !== 'hotel') &&
          !orderStatusFailed
      "
    >
      <PaymentInformation
        :show-transaction-fee="!!transaction_fee"
        :show-tips="!!tips"
        :show-payment-type="is_paid"
        :transaction-fee="transaction_fee"
        :tips="tips"
        :total="total"
        :subtotal="subtotal"
        :payments-sum="payments_sum"
        :payment-data="payment_data"
        :discount-code="coupon"
        :discount-amount="discount"
        :pay-at-restaurant="pay_at_restaurant"
        :small-order-fee="small_order_fee"
      ></PaymentInformation>
      <RequestReceiptButton
        v-if="paymentUuid"
        :session="sessionToken"
        :payment-uuid="paymentUuid"
      />
      <div
        v-if="$store.getters.functionalityRequestInvoiceEnabled && paymentUuid"
        class="mt-6"
      >
        <DividerWithText :text="$t('generic.or')" class="mt-3 mb-3" />
        <Button gray x-small dark @click="goToRequestInvoice">
          {{ $t('components.requestInvoice.button') }}
        </Button>
      </div>
    </LayoutCard>
    <LayoutCard v-if="showReviewsBottom" rounded>
      <LeaveReview
        :unique-order-number="unique_order_number"
        :review="review"
      />
    </LayoutCard>
    <LayoutCard rounded :title="$t('screens.order.orderInformationTitle')">
      <div class="tp-text-body">
        <div>
          {{ $t('screens.order.orderNumberText', { orderNumber: order_id }) }}
        </div>
        <div class="mt-1">
          {{
            $t('screens.order.orderIdText', { orderId: unique_order_number })
          }}
        </div>
        <div class="mt-1">
          {{ $t('screens.order.orderTimeText', { time: getOrderDateTime }) }}
        </div>
        <div class="mt-1">{{ $t('screens.order.orderProviderText') }}</div>
      </div>
    </LayoutCard>
    <LayoutCard
      v-if="!$store.getters.customizationOrderInfoHideRestaurantInfo"
      rounded
      :title="$t('screens.order.restaurantInformationTitle')"
    >
      <div class="tp-text-body">
        <div>{{ $store.state.restaurant.name }}</div>
        <div v-if="$store.state.restaurant.address" class="mt-1">
          {{ $store.state.restaurant.address }}
        </div>
        <div v-if="$store.state.restaurant.phone" class="mt-1">
          {{ $store.state.restaurant.phone }}
        </div>
      </div>
    </LayoutCard>
    <div class="ml-8 mr-8">
      <Button v-if="$store.state.restaurant.phone" dark @click="callRestaurant">
        <span v-if="$store.getters.customizationOrderInfoContactButtonText">{{
          $t($store.getters.customizationOrderInfoContactButtonText)
        }}</span>
        <span v-else-if="$store.state.restaurant.type === 'hotel'">{{
          $t('screens.order.contactHotel')
        }}</span>
        <span v-else>{{ $t('screens.order.contactRestaurant') }}</span>
      </Button>
    </div>
    <NotificationModal
      :is-open="showAllowNotificationsModal"
      @close-click="disallowNotifications"
      @cta-click="allowNotifications"
    />
  </div>
</template>

<script src="https://js.pusher.com/beams/1.0/push-notifications-cdn.js"></script>
<script>
import { delay, get } from 'lodash';
import formatPriceWithCurrency from '@/helpers/formatPriceWithCurrency.js';
import LayoutCard from '@/components/LayoutCard.vue';
import LeaveReview from '@/components/Reviews/Review.vue';
import Button from '@/components/Button.vue';
import NotificationModal from '@/components/Orders/NotificationModal.vue';
import NotificationBanner from '@/components/Orders/NotificationBanner.vue';
import { NOTIFICATION_PREFERENCES } from '../../../utils/constants.js';
import PaymentInformation from '@/components/Orders/OrderInfo/PaymentInformation.vue';
import PaymentInformationHotel from '@/components/Orders/OrderInfo/PaymentInformationHotel.vue';
import Spinner from '@/components/Spinner.vue';
import PersonnelPicker from '@/components/Tips/PersonnelPicker.vue';
import OrderStatus from '@/components/Orders/OrdersView/OrderStatus.vue';
import RequestReceiptButton from '@/components/Payments/RequestReceiptButton.vue';
import DividerWithText from '@/components/common/DividerWithText.vue';

export default {
  components: {
    LayoutCard,
    LeaveReview,
    Button,
    NotificationModal,
    NotificationBanner,
    PaymentInformation,
    PaymentInformationHotel,
    Spinner,
    PersonnelPicker,
    OrderStatus,
    RequestReceiptButton,
    DividerWithText,
  },
  props: {
    order_id: [String, Number],
    unique_order_number: String,
    status: String,
    comment: String,
    items: Array,
    subtotal: Number,
    transaction_fee: Number,
    tips: Number,
    total: Number,
    is_paid: Boolean,
    payments_sum: Number,
    small_order_fee: Number,
    payment_data: [Array, Object],
    seen: Boolean,
    wait_minutes: Number,
    accepted_at: String,
    created_at: String,
    placed_at: String,
    review: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    coupon: String,
    pay_at_restaurant: Boolean,
    discount: Number,
    headless: {
      type: Boolean,
      default: false,
    },
    addTopPadding: {
      type: Boolean,
      default: false,
    },
    payments: [Object, Array],
    batch: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    sending_to_pos: {
      type: Boolean,
      required: false,
      default: false,
    },
    sent_to_pos: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showAllowNotificationsModal: false,
      showAllowNotificationsBanner: false,
      phoneNumber: '',
      fetchOrdersInterval: null,
    };
  },
  computed: {
    orderStatusDelivered() {
      return this.status === 'closed';
    },
    orderStatusFailed() {
      return this.status === 'failed';
    },
    orderStatusRejected() {
      return this.status === 'rejected';
    },
    getDeliveryTime() {
      if (this.accepted_at && this.wait_minutes) {
        var date = new Date(this.accepted_at);
        date.setMinutes(date.getMinutes() + this.wait_minutes);
        var current_time =
          ('0' + date.getHours()).slice(-2) +
          ':' +
          ('0' + date.getMinutes()).slice(-2);
        return current_time;
      }

      return null;
    },
    getItems() {
      return this.items
        .map(item => {
          let totalPrice = 0;
          totalPrice = totalPrice + item.price;
          item.modifiers.forEach(modifier => {
            totalPrice = totalPrice + modifier.price;
          });
          return {
            ...item,
            totalPrice: totalPrice,
          };
        })
        .sort(
          (itemA, itemB) =>
            Number(itemB.is_delivered) - Number(itemA.is_delivered)
        );
    },
    getLeftToPayAmountFormatted() {
      const leftToPay = this.subtotal - this.payments_sum;

      if (leftToPay <= 0) {
        return false;
      }

      return formatPriceWithCurrency(
        leftToPay,
        this.$store.state.restaurant.currency.code,
        this.$store.state.restaurant.locale.locale_code
      );
    },
    getOrderDateTime() {
      const date = new Date(this.placed_at);
      return (
        date.toLocaleTimeString(
          this.$store.state.selectedLanguage ||
            this.$store.state.locale.locale_code,
          {
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
          }
        ) +
        ', ' +
        date.toLocaleDateString(
          this.$store.state.selectedLanguage ||
            this.$store.state.locale.locale_code,
          {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          }
        )
      );
    },
    showReviewsTop() {
      return (
        this.orderStatusDelivered &&
        !this.$store.getters.customizationOrderInfoHideReviews
      );
    },
    showReviewsBottom() {
      return (
        !this.orderStatusDelivered &&
        !this.orderStatusFailed &&
        !this.orderStatusRejected &&
        !this.$store.getters.customizationOrderInfoHideReviews
      );
    },
    paymentUuid() {
      return get(this.payments, '[0].uuid', null);
    },
    sessionToken() {
      return this.$store.state.session;
    },
    sessionPhoneNumber() {
      return this.$store.getters.getSessionPhoneNumber;
    },
    tableTypeIsPickup() {
      return this.$store.getters.tableTypeIsPickup;
    },
  },
  mounted() {
    this.$store.dispatch('fetchOrders');

    if (this.getNotificationPreferencesFromCookie() == 'granted') {
      this.initializeNotifications();
    }

    // const notificationPreferencesFromCookie = this.getNotificationPreferencesFromCookie();
    const sessionPhoneNumber = this.$store.getters.getSessionPhoneNumber;
    const openModalWithDelay = () =>
      delay(() => (this.showAllowNotificationsModal = true), 2000);

    // TODO - delete this if too much time passes
    // if (
    //   'Notification' in window &&
    //   this.$store.getters.functionalityPushNotificationsEnabled
    // ) {
    //   if (
    //     Notification.permission === 'default' &&
    //     notificationPreferencesFromCookie === null
    //   ) {
    //     openModalWithDelay();
    //   } else if (
    //     notificationPreferencesFromCookie === NOTIFICATION_PREFERENCES.DENIED
    //   ) {
    //     this.showAllowNotificationsBanner = true;
    //   }
    // } else if (
    //   notificationPreferencesFromCookie === NOTIFICATION_PREFERENCES.DENIED &&
    //   this.$store.getters.functionalityPushNotificationsEnabled
    // ) {
    //   this.showAllowNotificationsBanner = true;
    if (
      !sessionPhoneNumber &&
      this.$store.getters.functionalitySMSNotificationsEnabled &&
      this.tableTypeIsPickup
    ) {
      openModalWithDelay();
    }

    this.initiateFetchOrdersInterval();
  },
  methods: {
    formatPrice(price) {
      return formatPriceWithCurrency(
        price,
        this.$store.state.restaurant.currency.code,
        this.$store.state.restaurant.locale.locale_code
      );
    },
    close() {
      this.$store.dispatch('closeOrder');
    },
    allowNotifications(countryCallingCode, nationalNumber) {
      if (countryCallingCode && nationalNumber) {
        this.savePhoneNumber(countryCallingCode, nationalNumber);
      } else {
        this.showAllowNotificationsModal = false;
        this.showAllowNotificationsBanner = false;
        this.setNotificationPreferencesToCookies(
          NOTIFICATION_PREFERENCES.GRANTED
        );
        this.initializeNotifications();
        this.$store.dispatch('sendAnalyticsEvent', {
          event: 'allow_notifications',
        });
      }
    },
    disallowNotifications() {
      this.showAllowNotificationsModal = false;
      this.showAllowNotificationsBanner = true;
      this.setNotificationPreferencesToCookies(NOTIFICATION_PREFERENCES.DENIED);
      this.$store.dispatch('sendAnalyticsEvent', {
        event: 'disallow_notifications',
      });
    },
    savePhoneNumber(countryCallingCode, nationalNumber) {
      try {
        this.axios.post(
          `/session/${this.$store.state.session}/add-phone-number`,
          {
            countryCallingCode,
            nationalNumber,
          }
        );
        this.setNotificationPreferencesToCookies(
          NOTIFICATION_PREFERENCES.PHONE
        );
        this.$store.dispatch(
          'setSessionPhoneNumber',
          `+${countryCallingCode}${nationalNumber}`
        );
        this.showAllowNotificationsModal = false;
        this.showAllowNotificationsBanner = false;
      } catch (error) {
        console.log(error);
      }
    },
    getNotificationPreferencesFromCookie() {
      var match = document.cookie.match(
        new RegExp('(^| )allow-notifications=([^;]+)')
      );
      if (match) {
        return match[2];
      }

      return null;
    },
    setNotificationPreferencesToCookies(preference) {
      let date = new Date();
      // 1 day
      date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
      const expires = 'expires=' + date.toUTCString();
      document.cookie =
        'allow-notifications=' + preference + '; ' + expires + '; path=/';
    },
    initializeNotifications() {
      if (typeof Notification !== 'undefined') {
        console.log('initialized');
        // Push notifications
        const beamsClient = new PusherPushNotifications.Client({
          instanceId: process.env.VUE_APP_PUSHER_BEAMS_INSTANCE_ID,
        });

        beamsClient
          .start()
          .then(beamsClient => beamsClient.getDeviceId())
          .then(() => beamsClient.addDeviceInterest(this.$store.state.session))
          .catch(console.error);
      }
    },
    callRestaurant() {
      if (this.$store.state.restaurant.phone) {
        document.location.href = `tel:${this.$store.state.restaurant.phone.replace(
          / /g,
          ''
        )}`;
      }
    },
    goToRequestInvoice() {
      this.$router.push({
        name: 'RequestInvoice',
        params: {
          paymentUuid: this.paymentUuid,
        },
      });
    },
    initiateFetchOrdersInterval() {
      // While the order is open, fetch orders every 15 seconds
      if (this.fetchOrdersInterval === null && !this.orderStatusDelivered) {
        this.fetchOrdersInterval = setInterval(() => {
          this.$store.dispatch('fetchOrders');
        }, 15000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.order-info {
  height: 90vh;
  height: calc((var(--app-height) - 30) * 1px);
  background-color: $color-background-gray !important;
  border-radius: 20px 20px 0 0 !important;
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 220;
  overscroll-behavior-y: none;
  padding-bottom: 40px;
  overflow: scroll;
  box-shadow: 0px 1px 18px rgb(0 0 0 / 12%);

  @media screen and (min-width: $max-app-width) {
    left: undefined;
    max-width: $max-app-width;
    margin-left: auto;
    margin-right: auto;
  }

  &__close-btn-wrapper {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 30px;
  }

  &__close-btn {
    background-color: rgba(158, 158, 158, 0.7);
    border-radius: 32px;
    position: fixed;
    z-index: 50;
    width: 30px;
  }
}

.order-info-headless {
  padding-bottom: 40px;
}

.indicator {
  background: $color-gray-500;
}

.order-info-summary-items {
  &__item {
    display: flex;
    justify-content: space-between;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}

.order-info-summary-items-item {
  &__name-wrapper {
    display: flex;
    justify-content: center;
  }
  &__quantity {
    width: 20px;
    min-width: 20px;
    height: 20px;
    border-radius: 3px;
    background-color: $color-black;
    color: $color-white;
    display: flex;
    justify-content: center;
    align-items: center;

    &.delivered {
      background-color: $color-success;
    }
  }
}

.order-info-summary-payment-type {
  &__card-wrapper {
    display: flex;
    gap: 8px;
  }
  &__last-digits {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
  }
}

.allow-notifications-wrapper {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  background: rgb(0 0 0 / 30%);
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.allow-notifications {
  background: #ffffff;
  border-radius: 5px;
  margin: 0 43px;
  padding: 24px;

  &__controls {
    display: flex;
    justify-content: space-between;
  }
}

.allow-notifications-controls {
  &__allow {
    font-weight: 700;
  }
}
</style>
