import Vue from 'vue';

export default {
  async submitInvoiceRequest(_, { data }) {
    await Vue.axios.post(
      `${process.env.VUE_APP_API_URL}payments/request-invoice-v2`,
      data
    );
  },
  async requestReceipt({ dispatch }, { email, paymentId, onSuccess, onError }) {
    try {
      await Vue.axios.post(
        `${process.env.VUE_APP_API_URL}payments/request-receipt`,
        {
          email,
          payment_id: paymentId,
        }
      );

      onSuccess();
      dispatch('showToastInstantly', {
        title: Vue.i18n.translate(
          'components.requestReceiptButton.successToast.title'
        ),
        description: Vue.i18n.translate(
          'components.requestReceiptButton.successToast.description'
        ),
      });
    } catch (error) {
      onError();
      console.log(error);
    }
  },
  async requestReceiptV2(
    { dispatch },
    { email, paymentUuid, onSuccess, onError }
  ) {
    try {
      await Vue.axios.post(
        `${process.env.VUE_APP_API_URL}payments/request-receipt-v2`,
        {
          email,
          payment_uuid: paymentUuid,
        }
      );

      onSuccess();
      dispatch('showToastInstantly', {
        title: Vue.i18n.translate(
          'components.requestReceiptButton.successToast.title'
        ),
        description: Vue.i18n.translate(
          'components.requestReceiptButton.successToast.description'
        ),
      });
    } catch (error) {
      onError();
      console.log(error);
    }
  },
};
