<template>
  <div>
    <SplashHeader :cover="restaurantCover" />
    <div
      class="d-flex flex-column justify-center align-center text-center px-4"
    >
      <Avatar
        :photo-url="restaurantLogo"
        :lazy-src="restaurantLogo"
        data-cy="restaurant-logo"
        :size="140"
      />
      <h2 class="mt-4 tp-title-medium" data-cy="restaurant-name">
        {{ $t(restaurantName) }}
      </h2>
      <p
        v-if="restaurantDescription"
        class="mt-2 mb-0 tp-text-body"
        data-cy="restaurant-description"
      >
        {{ $t(restaurantDescription) }}
      </p>
      <SocialLinks :social-links="socialLinks" />
      <p v-if="tableName" class="mt-4 mb-0 tp-text-caption-dark">
        {{ tableName }}
      </p>
      <div class="w-100 mt-3">
        <Button
          v-for="(action, index) in actions"
          :key="index"
          :data-cy="action.dataCy"
          block
          class="mt-4 justify-space-between"
          gray
          @click="action.action"
        >
          <div
            class="grey darken-4 d-flex align-center rounded-lg justify-center"
            style="width: 30px; height: 30px; min-width: 30px; min-height: 30px;"
          >
            <CtaIcon :name="action.icon" />
          </div>
          <div class="text-left w-100 ml-4">
            {{ action.title }}
          </div>
          <v-icon v-show="!action.hideChevron" right color="grey darken-4">
            mdi-chevron-right
          </v-icon>
        </Button>
      </div>
    </div>
    <ReviewDrawer ref="reviewDrawer" />
  </div>
</template>

<script>
import compact from 'lodash/compact';

import Button from '@/components/Button.vue';
import SplashHeader from '@/components/SplashScreen/Header.vue';
import Avatar from '@/components/common/Avatar.vue';
import SocialLinks from '@/components/common/SocialLinks.vue';
import CtaIcon from '@/components/SplashScreen/CtaIcon.vue';
import ReviewDrawer from '@/components/Reviews/ReviewDrawer.vue';

export default {
  components: {
    Button,
    SplashHeader,
    Avatar,
    SocialLinks,
    CtaIcon,
    ReviewDrawer,
  },
  props: {
    status: {
      type: String,
      default: 'active',
    },
    failure: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    session() {
      return this.$store.state.session;
    },
    loadingScreenCustomizations() {
      return this.$store.getters.loadingScreenCustomizations;
    },
    restaurantLogo() {
      const loadingScreenCustomizations = this.loadingScreenCustomizations;
      const customSplashScreenLogo = loadingScreenCustomizations.logo;
      const restaurantLogo = this.$store.getters.restaurantLogo;
      const defaultLogo = require('@/assets/images/merchant.svg');

      return restaurantLogo || customSplashScreenLogo || defaultLogo;
    },
    restaurantCover() {
      const splashCover = this.$store.getters.restaurantSplashCover;
      const loadingScreenCustomizations = this.loadingScreenCustomizations;
      const customSplashScreenCover =
        loadingScreenCustomizations.background_image;

      return splashCover || customSplashScreenCover;
    },
    restaurantName() {
      return this.$store.getters.restaurantName;
    },
    restaurantDescription() {
      return this.$store.getters.restaurantDescription;
    },
    socialLinks() {
      return this.$store.getters.restaurantSocialLinks;
    },
    tableName() {
      const restaurant = this.$store.getters.restaurant;
      const isRestaurantType = restaurant.type === 'restaurant';
      const table = this.$store.state.table.name;
      const hideTableNumber = this.$store.getters
        .customizationSelectScreenHideTableName;

      if (!table || hideTableNumber) {
        return null;
      }

      if (isRestaurantType) {
        return this.$t('screens.selectScreen.tableName', {
          tableNumber: table,
        });
      }

      return this.$t('screens.selectScreen.tableNameWithoutTable', {
        tableNumber: table,
      });
    },
    hideMenuButton() {
      return this.$store.getters.customizationSelectScreenHideViewMenuButton;
    },
    ordersEnabled() {
      return (
        this.$store.getters.functionalityOrderEnabled &&
        !this.$store.getters.ordersTemporarilyDisabled
      );
    },
    viewMenuButtonTitle() {
      if (this.$store.getters.customizationSelectScreenViewMenuText) {
        return this.$t(
          this.$store.getters.customizationSelectScreenViewMenuText
        );
      } else if (this.ordersEnabled) {
        return this.$t('screens.selectScreen.viewMenuAndOrderCta');
      } else {
        return this.$t('screens.selectScreen.viewMenuCta');
      }
    },
    viewMenuButtonIcon() {
      if (this.ordersEnabled) {
        return 'order';
      }
      return 'menu';
    },
    callWaiterEnabled() {
      return this.$store.getters.functionalityCallWaiterEnabled;
    },
    isPayGoEnabled() {
      return this.$store.getters.functionalityPayGoEnabled;
    },
    selectScreenButtons() {
      return this.$store.getters.selectScreenButtons;
    },
    showPaymentHistoryButton() {
      return this.$store.getters.functionalityPayGoEnabled;
    },
    actions() {
      const menuButton = !this.hideMenuButton && {
        title: this.viewMenuButtonTitle,
        icon: this.viewMenuButtonIcon,
        action: this.navigateToMenu,
      };
      const callWaiterButton = this.callWaiterEnabled && {
        title: this.$t('screens.selectScreen.callWaiterCta'),
        icon: 'callWaiter',
        dataCy: 'select-screen-call-waiter',
        action: this.callWaiter,
        hideChevron: true,
      };
      const payGoButton = this.isPayGoEnabled && {
        title: this.$t('screens.selectScreen.payBillCta'),
        icon: 'bill',
        action: this.navigateToPayGo,
      };
      const paymentHistoryButton = this.showPaymentHistoryButton && {
        title: this.$t('screens.selectScreen.paymentsCta'),
        icon: 'history',
        dataCy: 'payment-history-button',
        action: this.navigateToPaymentHistory,
      };
      const leaveReviewButton = {
        title: this.$t('screens.selectScreen.leaveReviewCta'),
        icon: 'review',
        action: () => {
          this.$refs.reviewDrawer.toggle();
        },
        hideChevron: true,
      };
      const customButtons = this.selectScreenButtons.map(button => {
        const tipButton = button.link?.includes('/tip/');
        const icon = tipButton ? 'tip' : button.icon || 'link';
        return {
          title: button.text,
          icon,
          dataCy: button.dataCy,
          action: () => this.onCustomButtonClicked(button),
        };
      });

      return compact([
        menuButton,
        payGoButton,
        paymentHistoryButton,
        callWaiterButton,
        leaveReviewButton,
        ...customButtons,
      ]);
    },
  },
  mounted() {
    const dispatch = this.$store.dispatch;
    dispatch('sendAnalyticsEvent', {
      event: 'loading_screen',
    });

    if (this.failure) {
      dispatch('sendAnalyticsEvent', {
        event: 'fail_screen_displayed',
      });
    }

    if (this.isPayGoEnabled) {
      dispatch('fetchOrdersFromPOS');
    }
  },
  methods: {
    navigateToMenu() {
      const session = this.session;

      if (this.$store.getters.restaurantMenuFilters.length > 0) {
        this.$router.push({
          name: 'MenuFilters',
          params: { session },
        });
      } else {
        this.$router.push({
          name: 'Menu',
          params: { session },
        });
      }
    },
    callWaiter() {
      this.$store.dispatch('callWaiter', {});
      this.$store.dispatch('showToastInstantly', {
        title: this.$t('components.callWaiter.toast.title'),
        description: this.$t('components.callWaiter.toast.description'),
        doNotClose: true,
      });
    },
    navigateToPayGo() {
      const session = this.session;
      const orderNumber = this.$store.getters.getFirstPOSOpenOrderId;

      this.$router.push({
        name: 'Order',
        params: { session: session },
        query: orderNumber ? { orderNumber } : {},
      });
    },
    navigateToPaymentHistory: function() {
      this.$router.push({ name: 'PaymentHistory' });
    },
    onCustomButtonClicked(button) {
      if (button.link && button.open_new_tab) {
        return window.open(button.link, '_blank');
      }

      return (window.location = button.link);
    },
  },
};
</script>

<style lang="scss"></style>
