<template>
  <div>
    <LoyaltyPricing v-if="loyaltyProgramEnabled" redirect="PlaceOrder" />
    <LayoutCard rounded>
      <PriceSummary
        :subtotal-amount="subtotalAmount"
        :total-amount="totalAmount"
        :tips-amount="tipAmount"
        :discount-amount="discountAmount"
        :coupon-code="discountCode"
        :loyalty-discount-amount="loyaltyDiscountAmount"
        :loyalty-program="loyaltyProgram"
        :extra-fees="extraFees"
        :show-total="false"
      />
    </LayoutCard>
  </div>
</template>

<script>
import LayoutCard from '@/components/LayoutCard.vue';
import PriceSummary from '@/components/common/PriceSummary.vue';
import LoyaltyPricing from '@/components/Loyalty/LoyaltyPricing.vue';

export default {
  components: {
    LayoutCard,
    PriceSummary,
    LoyaltyPricing,
  },
  computed: {
    subtotalAmount() {
      return this.$store.getters.cartAmount;
    },
    totalAmount() {
      return this.$store.getters.cartSubtotalAmount;
    },
    tipAmount() {
      return this.$store.getters.tipAmount;
    },
    tipAmountFormatted() {
      return this.$store.getters.getFormattedPrice(this.tipAmount);
    },
    discountCode() {
      return this.$store.getters.discountCode;
    },
    discountAmount() {
      return this.$store.getters.discountAmount;
    },
    cartSmallOrderFeeAmount() {
      return this.$store.getters.cartSmallOrderFeeAmount;
    },
    showCartOrderValue() {
      return this.tipAmount || this.discountAmount;
    },
    cartTotalTitle() {
      if (this.showCartOrderValue) {
        return this.$t('components.cartSubtotal.grandTotal');
      }

      return this.$t('components.cartSubtotal.orderValue');
    },
    loyaltyProgramEnabled() {
      return this.$store.getters.functionalityLoyaltyProgramEnabled;
    },
    loyaltyDiscountAmount() {
      return this.$store.getters.loyaltyDiscountAmount;
    },
    loyaltyProgram() {
      return this.$store.getters.enrolledRestaurantLoyaltyProgram;
    },
    extraFees() {
      return this.$store.getters.cartExtraFees;
    },
  },
};
</script>

<style></style>
