<template>
  <v-bottom-sheet
    v-model="open"
    rounded="xl b-0"
    inset
    :max-width="maxAppWidth"
  >
    <v-sheet class="px-4 py-6" rounded="xl b-0">
      <div class="d-flex flex-row align-center justify-space-between pb-4">
        <span class="tp-text-subtitle-semibold">
          {{ $t('components.localeSelector.automaticTranslations.title') }}
        </span>
        <img
          :src="require(`@/assets/images/icons/chevron-down-white-icon.svg`)"
          @click="close"
        />
      </div>
      <div class="tp-text-body-gray">
        {{ $t('components.localeSelector.automaticTranslations.subtitle') }}
      </div>
      <v-list flat class="pt-6">
        <v-list-item-group color="primary">
          <template v-for="(language, key) in restaurantLanguages">
            <v-list-item
              :key="`locale-${key}`"
              class="pa-0"
              @click="changeLanguage(language)"
            >
              <v-list-item-icon class="mr-2">
                <img :src="language.image" style="width: 22px" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  class="tp-text-body-2"
                  v-text="language.name"
                ></v-list-item-title>
              </v-list-item-content>
              <v-list-item-action class="mr-0">
                <v-checkbox
                  color="black"
                  class="mr-0"
                  :input-value="selectedLanguage === language.code"
                  @change="changeLanguage(language)"
                />
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
export default {
  data() {
    return {
      open: this.$store.state.languageSelectorOpen,
      languages: [
        {
          code: 'lt',
          name: 'Lietuvių',
          image: require('@/assets/images/flags/lt.svg'),
        },
        {
          code: 'en',
          name: 'English',
          image: require('@/assets/images/flags/en.svg'),
        },
        {
          code: 'uk',
          name: 'Українською',
          image: require('@/assets/images/flags/uk.svg'),
        },
        {
          code: 'el',
          name: 'Ελληνικά',
          image: require('@/assets/images/flags/el.svg'),
        },
        {
          code: 'pl',
          name: 'Polski',
          image: require('@/assets/images/flags/pl.svg'),
        },
        {
          code: 'ar',
          name: 'العربية',
          image: require('@/assets/images/flags/ar.svg'),
        },
        {
          code: 'fr',
          name: 'Français',
          image: require('@/assets/images/flags/fr.svg'),
        },
        {
          code: 'lv',
          name: 'Latviešu',
          image: require('@/assets/images/flags/lv.svg'),
        },
        {
          code: 'de',
          name: 'Deutsch',
          image: require('@/assets/images/flags/de.svg'),
        },
        {
          code: 'es',
          name: 'Español',
          image: require('@/assets/images/flags/es.svg'),
        },
        {
          code: 'it',
          name: 'Italiano',
          image: require('@/assets/images/flags/it.svg'),
        },
        {
          code: 'ru',
          name: 'Русский',
          image: require('@/assets/images/flags/ru.svg'),
        },
      ],
    };
  },
  computed: {
    restaurantLanguages: function() {
      const languages = this.languages.filter(language => {
        return (
          this.$store.state.restaurant.languages.includes(language.code) ||
          language.code === this.$store.state.restaurant.mainLanguage
        );
      });

      return [
        ...languages.filter(
          ({ code }) => code === this.$store.state.restaurant.mainLanguage
        ),
        ...languages.filter(
          ({ code }) => code !== this.$store.state.restaurant.mainLanguage
        ),
      ];
    },
    selectedLanguage: function() {
      return this.$store.state.selectedLanguage;
    },
  },
  watch: {
    '$store.state.languageSelectorOpen': function() {
      this.open = this.$store.state.languageSelectorOpen;
    },
    open: function() {
      if (!this.open) {
        this.$store.dispatch('closeLanguageSelector');
      }
    },
  },
  methods: {
    close() {
      this.open = false;
    },
    async changeLanguage(language) {
      if (
        !this.$store.state.fetchedLanguages.includes(language.code) &&
        this.$store.state.restaurant.mainLanguage !== language.code
      ) {
        const translations = (await import(`../i18n/${language.code}.js`))
          .default;
        this.$i18n.add(language.code, translations);
        this.$store.dispatch('fetchTranslationsForLanguage', language.code);
      }

      this.$i18n.set(language.code);
      this.close();

      this.$store.dispatch('sendAnalyticsEvent', {
        event: 'language_change',
        language: language.code,
      });

      this.axios.post(`/session/${this.$store.state.session}/update-language`, {
        language: language.code,
      });
      this.$store.dispatch('setSelectedLanguage', language.code);
    },
  },
};
</script>

<style lang="scss">
.locale-selector {
  &__translations-disclaimer {
    color: $color-dark;
  }
}

.locale-selector-translations-disclaimer {
  &__subtitle {
    color: $color-gray;
  }
}
</style>
