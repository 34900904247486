<template>
  <button
    v-ripple="ripple && { center: false }"
    class="button d-flex tp-text-subtitle-semibold-white"
    :class="{
      disabled: disabled,
      'button--dark': dark,
      'button--x-small': xSmall,
      'button--white': white,
      'button--gray': gray,
      'button--no-shadow': noShadow,
      'rounded-pill': pill,
      'button--small': small,
      'button--text': text,
      'button--block': block,
    }"
    :style="{ background: backgroundColor }"
    as="button"
    v-bind="$attrs"
    :disabled="disabled"
    v-on="$listeners"
  >
    <v-progress-circular
      v-if="loading"
      indeterminate
      :size="15"
      :width="2"
    ></v-progress-circular>
    <slot v-else></slot>
  </button>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dark: Boolean,
    xSmall: Boolean,
    white: {
      type: Boolean,
      default: false,
    },
    gray: {
      type: Boolean,
      default: false,
    },
    noShadow: {
      type: Boolean,
      default: true,
    },
    backgroundColor: {
      type: [String, Boolean],
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    pill: {
      type: Boolean,
      default: false,
    },
    text: {
      type: Boolean,
      default: false,
    },
    ripple: {
      type: Boolean,
      default: true,
    },
    block: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    click() {
      if (!this.loading && !this.disabled) {
        this.$emit('click');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.button {
  transition: padding 0.3s ease-in-out;

  background: linear-gradient(
    180deg,
    var(--color-button-gradient-top) 0%,
    var(--color-button-gradient-bottom) 557.28%
  );
  border-radius: 10px;
  padding: 16px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);

  &--dark {
    background: linear-gradient(180deg, #1c1c1c 0%, #000000 100%);
    color: white;
  }

  &--small {
    padding: 10px 20px !important;
    width: unset;
  }

  &--x-small {
    transition: padding 0.3s ease-in-out;
    margin-top: 20px;
    padding: 8px 20px !important;
  }

  &--white {
    background: white !important;
    color: black !important;
  }
  &--gray {
    background: #ebebeb !important;
    color: black !important;
  }

  &--no-shadow {
    box-shadow: none;
  }

  &--text {
    padding: 0;
    background: none !important;
    box-shadow: none !important;
    color: white !important;
  }

  &--block {
    width: 100%;
  }
}

.disabled {
  background: linear-gradient(180deg, rgba(95, 95, 95, 0.9) 0%, #5f5f5f 100%),
    #ffffff;
}
</style>
