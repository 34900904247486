import Vue from 'vue';

export const activateOrderBatchRequest = async ({
  session,
  uniqueOrderNumber,
}) => {
  return await Vue.axios.post(`/orders/activate-order-batch/${session}`, {
    unique_order_number: uniqueOrderNumber,
  });
};

export const activateCashOrderRequest = async ({
  session,
  order,
  selectedPaymentType,
}) => {
  return await Vue.axios.post(`/payments/cash/activate-cash-order`, {
    order,
    session,
    selected_payment_type: selectedPaymentType,
  });
};
