<template>
  <div>
    <div
      v-if="showCartOrderValue && !hideSubtotal"
      class="d-flex justify-space-between item"
    >
      <span class="tp-text-body">
        {{ $t('screens.payment.summaryAmountLabel') }}
      </span>
      <span class="tp-text-body">
        {{ subtotalFormatted }}
      </span>
    </div>
    <div v-if="amountBeingPaid" class="d-flex justify-space-between item">
      <span class="tp-text-body">
        {{ $t('screens.payment.summaryAmountBeingPaidLabel') }}
      </span>
      <span class="tp-text-body">
        {{ amountBeingPaidFormatted }}
      </span>
    </div>
    <div
      v-if="discountAmount || isDiscountTypePayAtRestaurant"
      class="d-flex justify-space-between item"
    >
      <span class="tp-text-body">
        <v-icon color="primary">mdi-tag-outline</v-icon>
        {{ couponCode }}
      </span>
      <span v-if="isDiscountTypePayAtRestaurant" class="tp-text-body">
        {{ $t('screens.payment.payAtRestaurantCouponText') }}
      </span>
      <span v-else class="tp-text-body"> -{{ discountAmountFormatted }} </span>
    </div>
    <div
      v-if="showLoyaltyDiscount"
      class="d-flex justify-space-between align-center item"
    >
      <span class="tp-text-body d-flex align-center">
        {{ $t('screens.payment.summaryLoyaltyDiscountLabel') }}
        <LoyaltyCardPictogram class="mx-2" />
      </span>
      <span class="tp-text-body"> -{{ loyaltyDiscountAmountFormatted }} </span>
    </div>
    <div v-if="transactionFee" class="d-flex justify-space-between item">
      <span class="tp-text-body">
        {{ $t('screens.payment.summaryFeeLabel') }}
      </span>
      <span class="tp-text-body">
        {{ feeFormatted }}
      </span>
    </div>
    <div v-if="smallOrderFee" class="d-flex justify-space-between item">
      <span class="tp-text-body">
        {{ $t('screens.payment.summarySmallOrderFeeLabel') }}
      </span>
      <span class="tp-text-body">
        {{ smallOrderFeeFormatted }}
      </span>
    </div>
    <div v-if="extraFees.length" class="d-flex flex-column item">
      <div
        v-for="extraFee in extraFees"
        :key="extraFee.name"
        class="d-flex justify-space-between item"
      >
        <span class="tp-text-body">
          {{ $t(extraFee.name) }}
        </span>
        <span class="tp-text-body">
          {{ getFormattedPrice(extraFee.price) }}
        </span>
      </div>
    </div>
    <div
      v-if="tipsAmount"
      class="d-flex justify-space-between item"
      data-cy="tips-summary"
    >
      <span class="tp-text-body">
        {{ $t('screens.payment.summaryTipsLabel') }}
      </span>
      <span class="tp-text-body">
        {{ tipsFormatted }}
      </span>
    </div>
    <div v-if="showTotal" class="d-flex justify-space-between item">
      <span class="tp-text-subtitle-medium">
        {{ cartTotalTitle }}
      </span>
      <span class="tp-text-subtitle-medium">
        {{ totalAmountFormatted }}
      </span>
    </div>
  </div>
</template>

<script>
import LoyaltyCardPictogram from '@/components/Loyalty/LoyaltyCardPictogram.vue';

export default {
  components: {
    LoyaltyCardPictogram,
  },
  props: {
    subtotalAmount: {
      type: Number,
      default: null,
    },
    totalAmount: {
      type: Number,
      default: null,
    },
    transactionFee: {
      type: Number,
      default: null,
    },
    tipsAmount: {
      type: Number,
      default: null,
    },
    discountAmount: {
      type: Number,
      default: null,
    },
    couponCode: {
      type: String,
      default: null,
    },
    smallOrderFee: {
      type: Number,
      default: null,
    },
    loyaltyDiscountAmount: {
      type: Number,
      default: null,
    },
    loyaltyProgram: {
      type: Object,
      default: null,
    },
    showTotal: {
      type: Boolean,
      default: true,
    },
    amountBeingPaid: {
      type: Number,
      default: null,
    },
    hideSubtotal: {
      type: Boolean,
      default: false,
    },
    extraFees: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    subtotalFormatted() {
      return this.$store.getters.getFormattedPrice(this.subtotalAmount);
    },
    feeFormatted() {
      return this.$store.getters.getFormattedPrice(this.transactionFee);
    },
    tipsFormatted() {
      return this.$store.getters.getFormattedPrice(this.tipsAmount);
    },
    totalAmountFormatted() {
      return this.$store.getters.getFormattedPrice(this.totalAmount);
    },
    discountAmountFormatted() {
      return this.$store.getters.getFormattedPrice(this.discountAmount);
    },
    smallOrderFeeFormatted() {
      return this.$store.getters.getFormattedPrice(this.smallOrderFee);
    },
    isDiscountTypePayAtRestaurant() {
      return this.$store.getters.isDiscountTypePayAtRestaurant;
    },
    loyaltyDiscountAmountFormatted() {
      return this.$store.getters.getFormattedPrice(this.loyaltyDiscountAmount);
    },
    amountBeingPaidFormatted() {
      return this.$store.getters.getFormattedPrice(this.amountBeingPaid);
    },
    loyaltyProgramImage() {
      return this.loyaltyProgram?.card_pictogram;
    },
    showLoyaltyDiscount() {
      return (
        this.loyaltyProgram &&
        this.loyaltyDiscountAmount > 0 &&
        !this.couponCode
      );
    },
    showCartOrderValue() {
      if (!this.showTotal) {
        return true;
      }
      return (
        this.tipsAmount ||
        this.discountAmount ||
        this.transactionFee ||
        this.smallOrderFee ||
        this.isDiscountTypePayAtRestaurant ||
        this.loyaltyDiscountAmount
      );
    },
    cartTotalTitle() {
      if (this.showCartOrderValue) {
        return this.$t('components.cartSubtotal.grandTotal');
      }

      return this.$t('components.cartSubtotal.orderValue');
    },
  },
  methods: {
    getFormattedPrice(price) {
      return this.$store.getters.getFormattedPrice(price);
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  padding-top: 12px;
}

.item:first-child {
  padding-top: 0;
}
</style>
