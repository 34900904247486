<template>
  <LayoutCard v-if="!enrolledLoyaltyProgram" rounded :title="$t(title)">
    <div class="d-flex" @click="navigateToRegister">
      <LoyaltyCard
        :small="true"
        :loyalty-program="restaurantLoyaltyProgram"
        width="64px"
        height="41px"
      />
      <div class="tp-text-body-gray pl-3 mx-auto">
        {{ $t(content, { loyaltyProgramName }) }}
      </div>
      <v-icon @click="navigateToRegister">
        mdi-chevron-right
      </v-icon>
    </div>
  </LayoutCard>
</template>

<script>
import LoyaltyCard from '@/components/Loyalty/LoyaltyCard.vue';
import LayoutCard from '@/components/LayoutCard.vue';

export default {
  components: {
    LoyaltyCard,
    LayoutCard,
  },
  props: {
    redirect: {
      type: String,
      default: 'PlaceOrder',
    },
    title: {
      type: String,
      default: 'screens.placeOrder.registerToSaveTitle',
    },
    content: {
      type: String,
      default: 'screens.placeOrder.registerToSaveContent',
    },
  },
  computed: {
    session() {
      return this.$store.state.session;
    },
    enrolledLoyaltyProgram() {
      return this.$store.getters.enrolledRestaurantLoyaltyProgram;
    },
    restaurantLoyaltyProgram() {
      return this.$store.getters.restaurantLoyaltyProgram();
    },
    loyaltyProgramName() {
      return this.restaurantLoyaltyProgram.name;
    },
    restaurantName() {
      return this.$store.state.restaurant.name;
    },
    amountWithLoyaltyDiscount() {
      return this.$store.getters.cartAmountWithLoyaltyPricingFormatted;
    },
    // TODO Might need to use this later. If not remove it.
    potentialDiscountAmount() {
      return this.$store.getters.potentialDiscountAmount;
    },
    loyaltyDiscountAmount() {
      return this.$store.getters.getFormattedPrice(
        this.potentialDiscountAmount
      );
    },
  },
  methods: {
    navigateToRegister: function() {
      this.$router.push({
        name: 'Register',
        params: {
          session: this.session,
        },
        query: {
          redirect: this.redirect,
        },
      });
    },
  },
};
</script>

<style></style>
