import Vue from 'vue';

import {
  canPayForOrderRequest,
  selectOrderItemsRequest,
  getSelectedOrderItemsRequest,
  fetchOrderFromPOSRequest,
  fetchPaymentByUuidRequest,
  fetchLastPaymentRequest,
  applyLoyaltyDiscountToOrderRequest,
} from './api';
import { getOrderDiscountAmount } from '../../../utils/orders';

const initialState = {
  POSOrders: [],
  applyingLoyaltyDiscountToOrder: false,
};

export default {
  state: initialState,
  mutations: {
    SET_POS_ORDERS(state, orders) {
      state.POSOrders = orders;
    },
    SET_APPLYING_LOYALTY_DISCOUNT_TO_ORDER(state, value) {
      state.applyingLoyaltyDiscountToOrder = value;
    },
    SET_ORDER_ITEM_BY_ID(state, { uniqueOrderNumber, order }) {
      const index = state.POSOrders.findIndex(
        order => order.unique_order_number === uniqueOrderNumber
      );
      if (index !== -1) {
        Vue.set(state.POSOrders, index, order);
      }
    },
  },
  actions: {
    async fetchOrdersFromPOS({ rootState, dispatch, commit }) {
      try {
        const response = await fetchOrderFromPOSRequest({
          session: rootState.session,
        });
        commit('SET_POS_ORDERS', response);

        if (response.length > 0) {
          dispatch('sendAnalyticsEvent', {
            event: 'app_pay-go_has-orders',
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    async selectOrderItems(
      { rootState },
      { uniqueOrderNumber, selectedOptions }
    ) {
      try {
        const { session } = rootState;
        const response = await selectOrderItemsRequest({
          session,
          uniqueOrderNumber,
          selectedOptions,
        });

        return response;
      } catch (error) {
        return error.response;
      }
    },
    async checkCanPayForOrder({ rootState, dispatch }, { uniqueOrderNumber }) {
      const { session } = rootState;
      try {
        const response = await canPayForOrderRequest({
          session,
          uniqueOrderNumber,
        });

        return response;
      } catch (error) {
        if (
          error.response.data.message ===
          'Order already has payments of other type'
        ) {
          dispatch('setToastData', {
            title: Vue.i18n.translate(
              'screens.payment.error.partAlreadyPaid.title'
            ),
            description: Vue.i18n.translate(
              'screens.payment.error.partAlreadyPaid.description'
            ),
            isError: true,
            doNotClose: true,
          });
          dispatch('openToast');
        } else if (
          error.response.data.message ===
          'Precheck already printed, cannot split bill'
        ) {
          dispatch('setToastData', {
            title: Vue.i18n.translate(
              'screens.payment.error.precheckPrinted.title'
            ),
            description: Vue.i18n.translate(
              'screens.payment.error.precheckPrinted.description'
            ),
            isError: true,
            doNotClose: true,
          });
          dispatch('openToast');
        } else if (
          error.response.data.message ===
          'Unpaid sum and dishes sum does not match'
        ) {
          dispatch('showToastInstantly', {
            title: Vue.i18n.translate(
              'screens.payment.error.paymentUnavailableGeneric.title'
            ),
            description: Vue.i18n.translate(
              'screens.payment.error.paymentUnavailableGeneric.description'
            ),
            isError: true,
            doNotClose: true,
          });
        }
        return false;
      }
    },
    async tryToGetLastPaymentIntent(
      { dispatch },
      { uniqueOrderNumber, paymentUuid }
    ) {
      if (paymentUuid) {
        try {
          return fetchPaymentByUuidRequest({ paymentUuid });
        } catch (error) {
          console.error(error);
        }
      }

      let retryAttempts = 0;
      const retryAttemptsLimit = 10;
      const retryInterval = 1000;

      while (retryAttempts < retryAttemptsLimit) {
        const paymentIntent = await dispatch('getLastPaymentIntent', {
          uniqueOrderNumber,
        });

        if (paymentIntent) {
          return paymentIntent;
        }

        retryAttempts += 1;

        await new Promise(resolve =>
          setTimeout(resolve, retryInterval * retryAttempts)
        );
      }
    },
    async getLastPaymentIntent({ rootState }, { uniqueOrderNumber }) {
      try {
        return fetchLastPaymentRequest({
          session: rootState.session,
          uniqueOrderNumber,
        });
      } catch (error) {
        console.error(error);
      }
    },
    async checkOrderNeoPayPaymentStatus(_, { token, onPaymentError }) {
      try {
        const response = await Vue.axios.post(
          `/payments/neopay/check-payment-status`,
          {
            token,
          }
        );

        const responseData = response.data;
        if (responseData.status === 'succeeded' && responseData.uuid) {
          const paymentDetailsResponse = await Vue.axios.get(
            `/payments/payment-by-uuid/${responseData.uuid}`
          );

          return paymentDetailsResponse.data.data;
        }

        return {
          status: 'initialized',
        };
      } catch (error) {
        console.error(error);
        if (onPaymentError) {
          onPaymentError();
        }
      }
    },
    async tryToFetchNeopayPaymentData(
      { dispatch },
      { token, onPaymentSuccess, onPaymentError }
    ) {
      let retryAttempts = 0;
      const retryAttemptsLimit = 10;
      const retryInterval = 10000;

      while (retryAttempts < retryAttemptsLimit) {
        const data = await dispatch('checkOrderNeoPayPaymentStatus', {
          token,
          onPaymentError,
        });

        if (data?.amount) {
          onPaymentSuccess(data);
          return;
        } else if (data?.status === 'failed') {
          onPaymentError();
          return;
        }

        retryAttempts += 1;

        await new Promise(resolve => setTimeout(resolve, retryInterval));
      }

      onPaymentError();
      return;
    },
    async fetchSelectedOrderItems({ rootState }, { uniqueOrderNumber }) {
      try {
        const response = await getSelectedOrderItemsRequest({
          session: rootState.session,
          uniqueOrderNumber,
        });

        return response;
      } catch (error) {
        console.error(error);
      }
    },
    async checkIfUnpaidItemsAreSelectedByOthers(
      { dispatch, getters },
      { uniqueOrderNumber }
    ) {
      try {
        const orderItems = getters.getPosOrderItems(uniqueOrderNumber);
        const response = await dispatch('fetchSelectedOrderItems', {
          uniqueOrderNumber,
        });
        const selectedOrderItemsByOthers = response?.others_selected;
        const unpaidOrderItems = orderItems.filter(item => !item.is_paid);
        const selectedOrderItemsNotPaid = unpaidOrderItems.filter(item =>
          selectedOrderItemsByOthers.includes(item.id)
        );

        if (selectedOrderItemsNotPaid.length > 0) {
          dispatch('showToastInstantly', {
            title: Vue.i18n.translate(
              'screens.order.someItemsSelectedToast.title'
            ),
            description: Vue.i18n.translate(
              'screens.order.someItemsSelectedToast.description'
            ),
            doNotClose: true,
            isError: true,
          });
        }

        return selectedOrderItemsNotPaid.length > 0;
      } catch (error) {
        console.error(error);
      }
    },
    async selectAllUnpaidOrderItems(
      { rootState, getters },
      { uniqueOrderNumber, onSuccess }
    ) {
      const session = rootState.session;
      const orderItems = getters.getPosOrderItems(uniqueOrderNumber);
      const unpaidItems = orderItems.filter(item => !item.is_paid);
      const selectedOptions = unpaidItems.map(item => item.id);

      try {
        const response = await selectOrderItemsRequest({
          session,
          uniqueOrderNumber,
          selectedOptions,
        });

        if (response) {
          onSuccess();
        }
        return response;
      } catch (error) {
        console.error(error);
      }
    },
    async deselectAllOrderItems({ rootState }, { uniqueOrderNumber }) {
      const session = rootState.session;

      try {
        const response = await selectOrderItemsRequest({
          session,
          uniqueOrderNumber,
          selectedOptions: [],
        });

        return response;
      } catch (error) {
        console.error(error);
      }
    },
    async applyLoyaltyDiscountToOrder(
      { rootState, commit, getters },
      { uniqueOrderNumber, onError, onSuccess }
    ) {
      const session = rootState.session;
      const user = getters.user;

      try {
        commit('SET_APPLYING_LOYALTY_DISCOUNT_TO_ORDER', true);

        const response = await applyLoyaltyDiscountToOrderRequest({
          session,
          uniqueOrderNumber,
          firebaseUid: user.uid,
        });
        const order = response?.data?.data;

        commit('SET_ORDER_ITEM_BY_ID', {
          uniqueOrderNumber,
          order: response.data.data,
        });
        commit('SET_APPLYING_LOYALTY_DISCOUNT_TO_ORDER', false);

        if (onSuccess) {
          const discountAmount = getOrderDiscountAmount(order);
          const discountAmountFormatted = getters.getFormattedPrice(
            discountAmount
          );

          onSuccess({ discountAmount, discountAmountFormatted });
        }

        return response;
      } catch (error) {
        commit('SET_APPLYING_LOYALTY_DISCOUNT_TO_ORDER', false);
        if (onError) {
          onError(error?.response?.data);
        }
      }
    },
  },
  getters: {
    POSOrders: state => state.POSOrders,
    getPOSOrderByUniqueOrderNumber: state => uniqueOrderNumber => {
      return state.POSOrders.find(
        order => order.unique_order_number === uniqueOrderNumber
      );
    },
    getFirstPOSOpenOrderId: (_, getters) => {
      const orders = getters.POSOrders.filter(order => {
        return order.status !== 'closed';
      });
      if (orders.length > 0) {
        return orders[0]?.unique_order_number;
      }
      return null;
    },
    getPosOrderItems: (_, getters) => uniqueOrderNumber => {
      const order = getters.getPOSOrderByUniqueOrderNumber(uniqueOrderNumber);
      return order?.items;
    },
    discountApplied: (_, getters) => uniqueOrderNumber => {
      const order = getters.getPOSOrderByUniqueOrderNumber(uniqueOrderNumber);
      const hasStrikethroughPrice = order?.items.some(
        item => item.strikethrough_price
      );

      return order?.app_user_loyalty_program_applied || hasStrikethroughPrice;
    },
    getApplyingLoyaltyDiscountToOrder: state =>
      state.applyingLoyaltyDiscountToOrder,
  },
};
