<template>
  <div>
    <Button
      :ripple="false"
      x-small
      text
      class="mt-3"
      data-cy="tip-success-receipt"
      @click="open"
    >
      {{ $t('screens.tipSuccess.receiptCta') }}
    </Button>
    <v-bottom-sheet
      :value="isOpen"
      class="bottom-sheet"
      :max-width="maxAppWidth"
      @click:outside="close"
    >
      <v-sheet class="text-left pa-3" rounded="xl b-0">
        <div class="d-flex pt-3 pb-2">
          <span class="tp-text-subtitle-medium-bold">
            {{ $t('generic.choose') }}
          </span>
        </div>
        <div class="d-flex flex-column w-100">
          <RequestReceiptButton
            v-if="paymentUuid"
            data-cy="tip-success-request-receipt"
            cta-text="screens.tipSuccess.requestReceiptCta"
            :session="sessionToken"
            :payment-uuid="paymentUuid"
            @on-close="close"
          />
          <DividerWithText :text="$t('generic.or')" class="mt-5 mb-2" />
          <Button
            :ripple="false"
            x-small
            gray
            class="mt-3 mb-6"
            @click="goToRequestInvoice"
          >
            {{ $t('screens.tipSuccess.invoiceCta') }}
          </Button>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import Button from '@/components/Button.vue';
import DividerWithText from '@/components/common/DividerWithText.vue';
import RequestReceiptButton from '@/components/Payments/RequestReceiptButton.vue';

export default {
  components: {
    Button,
    DividerWithText,
    RequestReceiptButton,
  },
  props: {
    paymentUuid: {
      type: String,
      required: true,
    },
    sessionToken: {
      type: String,
      required: true,
    },
  },
  data: () => {
    return {
      isOpen: false,
    };
  },
  computed: {
    isDisabled() {
      return !this.isValid;
    },
  },
  methods: {
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
    goToRequestInvoice() {
      this.$router.push({
        name: 'RequestInvoice',
        params: {
          paymentUuid: this.paymentUuid,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.bottom-sheet::v-deep {
  v-bottom-sheet {
    border-radius: 20px;
  }
}
</style>
