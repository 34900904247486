<template>
  <div class="enroll-container">
    <NavHeader
      :title="$t('screens.enrollLoyalty.navTitle')"
      @onBackClick="goBack"
    />
    <LayoutCard v-if="restaurantLoyaltyProgram" larger-container grow>
      <div class="text-center">
        <LoyaltyCard
          v-if="!externalLoyaltyProgram"
          :spin="false"
          :loyalty-program="restaurantLoyaltyProgram"
          class="mb-8"
        />
        <h2>
          {{ $t(title, { loyaltyProgramName }) }}
        </h2>
        <div
          v-if="externalLoyaltyProgram"
          class="tp-text-body mt-3 grey--text text--darken-2"
        >
          {{ $t('screens.enrollLoyalty.subtitle') }}
        </div>
      </div>
      <div>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="enroll"
        >
          <div class="mt-3 mb-2">
            <div v-for="(field, key) in additionalFields" :key="key">
              <div v-if="field.type === 'checkbox'">
                <v-checkbox
                  :key="field.key"
                  v-model="details[field.key]"
                  :rules="getFieldRules(field)"
                  :required="field.required"
                  class="ma-0"
                  :ripple="false"
                >
                  <template v-slot:label>
                    <div
                      class="text-left tp-text-label grey--text pt-1"
                      @click="changeOrder"
                    >
                      <div v-html="field.name" />
                    </div>
                  </template>
                </v-checkbox>
              </div>
              <v-text-field
                v-else
                :key="field.key"
                v-model="details[field.key]"
                :label="$t(field.name)"
                :placeholder="$t(field.placeholder)"
                :maxlength="field.max_length"
                :minlength="field.min_length"
                :required="field.required"
                :rules="getFieldRules(field)"
                :type="field.type"
              />
            </div>
          </div>
        </v-form>
      </div>
      <div class="mt-auto">
        <Button
          dark
          :loading="isEnrolling"
          :disabled="isEnrolling"
          @click="enroll"
        >
          {{ $t('screens.enrollLoyalty.ctaLabel') }}
        </Button>
        <div
          v-if="!externalLoyaltyProgram"
          class="tp-text-label grey--text text-center mt-3"
        >
          {{ $t('screens.loyaltyPreview.disclaimer') }}
        </div>
      </div>
    </LayoutCard>
  </div>
</template>

<script>
import NavHeader from '@/components/Navigation/NavHeader.vue';
import LayoutCard from '@/components/LayoutCard.vue';
import LoyaltyCard from '@/components/Loyalty/LoyaltyCard.vue';
import Button from '@/components/Button.vue';
import { PhoneEuropeRegExp } from '../../utils/constants';

export default {
  components: {
    Button,
    NavHeader,
    LoyaltyCard,
    LayoutCard,
  },
  data() {
    return {
      valid: true,
      checkbox: false,
      details: {},
      isEnrolling: false,
      checkboxRules: [v => !!v || this.$t('screens.register.agreeToTerms')],
    };
  },
  computed: {
    session() {
      return this.$store.state.session;
    },
    additionalFields() {
      const loyaltyId = this.restaurantLoyaltyProgram.id;
      return this.$store.getters.additionalLoyaltyFields(loyaltyId);
    },
    restaurantLoyaltyProgram() {
      const loyaltyProgramId = this.$route.params.loyaltyProgramId;
      return this.$store.getters.loyaltyProgramById(loyaltyProgramId);
    },
    externalLoyaltyProgram() {
      return this.restaurantLoyaltyProgram.is_external;
    },
    title() {
      if (this.externalLoyaltyProgram) {
        return 'screens.enrollLoyalty.titleExternal';
      }

      return 'screens.enrollLoyalty.title';
    },
    loyaltyProgramName() {
      return this.restaurantLoyaltyProgram.name;
    },
  },
  async mounted() {
    const sessionToken = this.$route.params.session;
    await this.$store.dispatch('fetchSessionData', { session: sessionToken });
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    goBack() {
      this.$router.go(-1);
    },
    getFieldRules(field) {
      const rules = [];
      const internationalPhoneRegEx = PhoneEuropeRegExp();
      if (field.required) {
        rules.push(v => !!v || this.$t('generic.requiredField'));
      }
      if (field.required && field.type === 'tel') {
        rules.push(
          v =>
            internationalPhoneRegEx.test(v) || this.$t('generic.invalidPhone')
        );
      }

      return rules;
    },
    async enroll() {
      await this.validate();
      this.isEnrolling = true;
      const redirectUrl = this.$route.query.redirect || 'PlaceOrder';

      if (!this.valid) {
        this.isEnrolling = false;
        return;
      }

      try {
        await this.$store.dispatch('enrollUser', {
          loyaltyProgram: this.restaurantLoyaltyProgram,
          details: this.details,
        });
        this.$router.replace({
          name: redirectUrl,
          params: {
            session: this.session,
          },
        });
      } catch (error) {
        this.isEnrolling = false;
      }

      this.isEnrolling = false;
    },
    changeOrder(e) {
      e.stopPropagation();
    },
  },
};
</script>

<style scoped lang="scss">
.enroll-container {
  display: flex;
  flex-flow: column;
  min-height: 100svh;
  height: 100%;
}
</style>
